import React from 'react';

import { Link } from 'react-router-dom';
import Milieu from '../assets/rur.jpg'
import Rurale from '../assets/20181029_101702.jpg'
import Bourses from '../assets/burs.jpg'
import Organi from '../assets/org.jpg'
import Context from "../assets/04_Afrique.jpg"
import Test1 from '../assets/test1.jpg'
import Test2 from '../assets/test2.jpg'
import { useTranslation } from 'react-i18next';

function Onglets(){
    const {t} = useTranslation();
    return(
        <div className="onglets" >
            
            <div className="goal" >
                        <Link to="/contexte" >
                        <div className="single_goal" >
                            {/* <div className="topGoal" > */}
                                {/* <i className="ion-ios-lightbulb" ></i> */}
                                <img src={Context} />
                                <h4>{t('A_1')}</h4>
                                {/* <h5>Former des femmes et des hommes qui vont concevoir la ville africaine.</h5> */}
                            {/* </div> */}
                        </div>
                        </Link>
                        
                        <Link to="/milieu" >
                        <div className="single_goal" >
                            
                            {/* <div className="topGoal" > */}
                            {/* <i className="ion-ios-lightbulb" ></i> */}
                                <img src={Milieu} />
                                <h4>{t('A_2')}</h4>
                                {/* <h5>Former des femmes et des hommes qui vont concevoir la ville africaine.</h5> */}
                            {/* </div> */}
                        </div>
                        </Link>

                        <Link to="/rurale" >
                        <div className="single_goal" >
                            
                            {/* <div className="topGoal" > */}
                            {/* <i className="ion-ios-lightbulb" ></i> */}
                                <img src={Rurale} />
                                <h4>{t('A_3')}</h4>
                                {/* <h5>Former des femmes et des hommes qui vont concevoir la ville africaine.</h5> */}
                            {/* </div> */}
                        </div>
                        </Link>
                        <Link to="/bourses" >
                        <div className="single_goal" >
                            
                            {/* <div className="topGoal" > */}
                            {/* <i className="ion-ios-lightbulb" ></i> */}
                                <img src={Bourses} />
                                <h4>{t('A_4')}</h4>
                                {/* <h5>Former des femmes et des hommes qui vont concevoir la ville africaine.</h5> */}
                            {/* </div> */}
                        </div>
                        </Link>
                        
                        <div className="single_goal" >
                            
                            {/* <div className="topGoal" > */}
                            {/* <i className="ion-ios-lightbulb" ></i> */}
                                <img src={Organi} id="org" />
                                <h4>{t('A_10')}</h4>
                                {/* <h5>Former des femmes et des hommes qui vont concevoir la ville africaine.</h5> */}
                            {/* </div> */}
                        </div>
                    
                        
                    </div>                    
                <div>


</div>
        </div>
    )
}

export default Onglets;