import React from 'react'
import Header from './shared/Header';
import Footer from './shared/Footer';
import "./Styles/Home.css";
import Img from './assets/pile-books-reading-getty-0918_horiz.jpg'
import Img1 from './assets/students_reading_online.jpg'
import Img2 from './assets/unnamed.jpg'
import Img3 from './assets/fd100bc73e_50147633_plane-te-technologie.jpg'
import Img4 from './assets/santiago-calatrava-architecture-07.jpg'
import ContexteI from './assets/04_Afrique.jpg'
import { Map, GoogleApiWrapper } from 'google-maps-react';
import SliderContext from './shared/SliderContext';
import { Helmet } from 'react-helmet'
import Onglets from './shared/Onglets';
import { useTranslation } from 'react-i18next';

function Contexte() {
    const TITLE = "asAf Gallerie"
    const {t} = useTranslation()
    return (
        <div className="mainDiv di" >
            
        <Helmet>
        <title>{ TITLE }</title>
        </Helmet>
            <div className="header_rel" >
                <Header/>
            </div>
            <SliderContext/>
            <div className="galleryContainerContexte" >
                <div className="relativeGal" >
                    <img src={ContexteI} />
                    <div className="details_contexte" >
                    {t('C_1')}<b>{t('C_2')}</b>
                    <br/>
                    <br/>
{t('C_3')}
<br/>
<br/>
{t('C_4')}
<br/>{t('C_5')}
<br/>{t('C_6')}
<br/>{t('C_7')}
<br/>{t('C_8')}
<br/>{t('C_9')}
<br/>{t('C_10')}
<br/>{t('C_11')}
<br/>{t('C_12')}
<br/>{t('C_13')}
<br/>{t('C_14')}
<br/>{t('C_16')}
<br/>
<br/>
{t('C_16')}
<br/><br/>
{t('C_17')}

                    </div>
                    <div className="yellow" >
                    {t('C_18')}
                    <br/><br/>{t('C_19')}

                    </div>
                    {/* <Footer/> */}
                <Onglets/>
                </div>
            </div>
        </div>
    )
}

export default Contexte
