import React from 'react'
import { useTranslation } from 'react-i18next'
import PDFStrategic from "../assets/h1.pdf"

function Footer() {
    const {t, i18n} = useTranslation();
    return (
        <div className="footer" >
            <div className="foo" >
                <div className="f_about" >
                    <h4>Horizon 24</h4>
                    <h5>
                        {
                            i18n.language==="fr"
                            ?
                            <a
                                target="_blank"
                                href="/frenchStrategicPlan.pdf"
                                download
                            >
                                {t('Footer_1')}
                            </a>
                            :
                            <a
                                target="_blank"
                                href="/englishStrategicPlan.pdf"
                                download
                            >
                                {t('Footer_1')}
                            </a>
                            
                        }
                    </h5>
                    <iframe
                        style={{ width: "100%", height: "800px", display:"none" }}
                        src="../assets/h1.pdf"
                      >
                        {" "}
                      </iframe>
                    <div className="icons" >
                        <i className='ion-social-instagram-outline' ></i>
                        <i className="ion-social-facebook" ></i>
                        <i className="ion-social-linkedin" ></i>
                        <i className="ion-android-mail" ></i>
                    </div>
                </div>
                <div className="f_contact">
                    <h4>{t('Footer_2')}</h4>
                    <ul>
                        <li>{t('Home')}</li>
                        <li>{t('Gallery')}</li>
                        <li>{t('Footer_3')}</li>
                    </ul>
                </div>
                <div className="f_quick" >
                    <h4>Contacts</h4>
                    <ul>
                        <li>+ 243 998 925 719</li>
                        <li>info@asafuniversity.com</li>
                        <li>asafuniversity.co</li>
                    </ul>
                </div>
                <div className="f_fbook" >
                    {/* <h4>Our social media</h4> */}
                    <div className="icons" >
                        <i className="ion-android-mail" ></i>
                        <i className='ion-social-instagram-outline' ></i>
                        <i className="ion-social-facebook" ></i>
                        <i className="ion-social-linkedin" ></i>
                        <i className="ion-android-call" ></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
