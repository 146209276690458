import React from 'react'
import Header from './shared/Header';
import Footer from './shared/Footer';
import "./Styles/Home.css";
import Img from './assets/pile-books-reading-getty-0918_horiz.jpg'
import Img1 from './assets/students_reading_online.jpg'
import Img2 from './assets/unnamed.jpg'
import Img3 from './assets/fd100bc73e_50147633_plane-te-technologie.jpg'
import Img4 from './assets/santiago-calatrava-architecture-07.jpg'
import Img5 from './assets/oj.jpg'
import Img6 from './assets/his.jpg'
import { Helmet } from 'react-helmet'

import { Map, GoogleApiWrapper } from 'google-maps-react';
import Slider from './shared/SliderH';
import { useTranslation } from 'react-i18next';

function History() {
    const {t} = useTranslation();
    const TITLE = "asAf Historique"
    return (
        <div className="mainDiv his" >
            
        <Helmet>
        <title>{ TITLE }</title>
        </Helmet>
            <div className="header_rel" >
                <Header/>
            </div>
            <Slider/>
            <div className="galleryContainerh" >
                <div className="relativeGHist hito" >
                    <div className = "intro" >
                        
                        <div className="twoh" >
                            <img src={Img6} id="imgo" />
                            <p>Camp Biblique GBU à Ifrane en 2000 (Maroc)</p>
                        </div>
                        <div className="oneh" >
                            <h2>{t('H_h2')}</h2>
                            <b><p></p></b>
                            {/* <p>Camp Biblique GBU à Ifrane en 2000 (Maroc)</p> */}
                            <p>
                            {t('H_h3')}
<br/>
<br/>
{t('H_h3_1')}
<br/>
<br/>
{t('H_h3_2')}
<br/>
<br/>
{t('H_h3_3')}
<br/>
<br/>
{t('H_h3_4')}
<br/>
<br/>
<b>{t('H_h4')}</b>
<br/>
{t('H_h5')}
<br/>
<br/>
{t('H_h5_1')}

                            </p>
                            
                            <p>
                                <b>
                                NGUNZA MUHINDO Johnny {t('H_h5_2')}
                                </b>
                            </p>
                            <span style={{fontSize:12, fontStyle:'italic'}}>* INTERNATIONAL FELLOWSHIP OF EVANGELICAL STUDENTS</span>
                        </div>
                    </div>

                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    
                    <div className="sectionh" >
                        <div className="onesection">
                            <div className="twoh" >
                                <img src={Img5} id="img"/>
                                <p>Université asAf, entrée principale</p>
                            </div>
                            <div className="oneh">
                                <h2>
                                    {t('H_h6')}
                                    <br/>
                                    {t('H_h7')}
                                </h2>
                                <p>
                                    {t('H_h8_1')}
                                    <br/>
                                    {t('H_h8_2')}
                                    <br/>
                                    {t('H_h8_3')}

                                </p>
                            </div>
                        </div>
                        <div className="donationForm" >
                            <h2>{t('H_h9')}</h2>
                            <form>
                                <input type = "text" placeholder="Name"/>
                                <input type = "text" placeholder="Email"/>
                                <input type = "text" placeholder="Contact"/>
                                <br/>
                                <button>
                                    {t('H_h10')}
                                </button>
                            </form>
                            <h3>
                                {t('H_h11')}
                            </h3>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default History
