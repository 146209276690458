import React from 'react'
import Header from './shared/Header';
import Footer from './shared/Footer';
import "./Styles/Home.css";
import Img from './assets/pile-books-reading-getty-0918_horiz.jpg'
import Img1 from './assets/students_reading_online.jpg'
import Img2 from './assets/unnamed.jpg'
import Img3 from './assets/fd100bc73e_50147633_plane-te-technologie.jpg'
import Img4 from './assets/santiago-calatrava-architecture-07.jpg'
import ContexteI from './assets/burs.jpg'
import { Map, GoogleApiWrapper } from 'google-maps-react';
import Slider from './shared/SliderBourse';
import { Helmet } from 'react-helmet'
import Onglets from './shared/Onglets';

import Test1 from './assets/test1.jpg'
import Test2 from './assets/test2.jpg'
import { useTranslation } from 'react-i18next';

function Bourses() {
    const TITLE = "asAf Gallerie"
    const {t} = useTranslation()
    return (
        <div className="mainDiv di" >
            
        <Helmet>
        <title>{ TITLE }</title>
        </Helmet>
            <div className="header_rel" >
                <Header/>
            </div>
            <Slider/>
            <div className="galleryContainerContexte" >
                <div className="relativeGal" >
                    <img src={ContexteI} id="run" />
                    <div className="details_contexte" >
                    {t('A_16')}
                    </div>
                    
                <div className="temoignent" >
                    <div className="one_t" >
                        <img src={Test1} />
                        <div className="text_test" >
                            <b>{t('A_5')}</b>
                            <p>{t('A_6')}</p>
                        </div>
                    </div>
                    <div className="one_t" >
                        <img src={Test2} />
                        <div className="text_test" >
                            <b>{t('A_7')}</b>
                            <p>
                            {t('A_8')}
                            </p>
                        </div>
                    </div>
                </div>
                <Onglets/>
                </div>
                    {/* <Footer/> */}
            </div>
        </div>
    )
}

export default Bourses
