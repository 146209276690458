import React from 'react'
import Header from './shared/Header';
import Footer from './shared/Footer';
import "./Styles/Home.css";
import Img from './assets/pile-books-reading-getty-0918_horiz.jpg'
import Img1 from './assets/students_reading_online.jpg'
import Img2 from './assets/unnamed.jpg'
import Img3 from './assets/fd100bc73e_50147633_plane-te-technologie.jpg'
import Img4 from './assets/santiago-calatrava-architecture-07.jpg'
import Img5 from './assets/urbanisme.jpg'
import { Map, GoogleApiWrapper } from 'google-maps-react';
import SliderTeam from './shared/SliderTeam';
import { Helmet } from 'react-helmet'


import Imgg from '../components/assets/johny.jpg'
import Robert from '../components/assets/robert.jpeg'
import Vincent from '../components/assets/vincent.jpg'
import Lul from '../components/assets/lulihi.jpg'
import kas from '../components/assets/kas.jpg'
import sak from '../components/assets/JUDITH2.jpg'
import alb from '../components/assets/alb.jpg'
import { useTranslation } from 'react-i18next';

function Equipe() {
    const {t}= useTranslation();
    const TITLE = "asAf notre é quipe"
    return (
        <div className="mainDiv" >
            
        <Helmet>
        <title>{ TITLE }</title>
        </Helmet>
            <div className="header_rel" >
                <Header/>
            </div>
            <SliderTeam/>
            <div className="facultyContent teamContent" >
                <div className="one" >
                    <div className="aboutSection" >
                        {/* <i className="ion-lightbulb" ></i> */}
                        {/* <h3>
                        <h1>Autres son d'Afrique</h1>
                        Connaitre les besoins vitaux de l’homme dans la ville. L’orientation  et la création des différentes filières sont définies en fonction des besoins vitaux de l’homme pour un développement durable. 
La ville ne doit pas seulement être faite de béton et d’acier... L’homme doit être au centre de la conception de nos villes de demain.

                        </h3> */}
                    </div>

                    

                    {/* <div className="Campus" > */}
                        {/* <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.6721342279307!2d29.463701214168633!3d0.4904733996421613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1761abaaca18e3a5%3A0x6595ae59fac8931f!2sUniversit%C3%A9+autre+son+d&#39;Afrique+-+asAf!5e0!3m2!1sfr!2scd!4v1479827895629" frameborder="0" allowfullscreen></iframe> */}
                        {/* <div className="campus_dtl" > */}
                            {/* <div className="info" > */}
                                {/* <h1>asAf - autre son d'Afrique</h1>
                                <h4>Localisation</h4> */}
                                {/* <ul>    
                                    <li>
                                        <i className="ion-android-globe" ></i>
                                        Beni (North-Kivu)
                                    </li>
                                                                    
                                    <li>
                                        <i className="ion-android-map" ></i>
                                        25 Avenue Sivirwa,
                                    </li>
                                    
                                    <li>
                                        <i className="ion-android-globe" ></i>
                                        République Démocratique du Congo
                                    </li>
                                    
                                    <li>
                                        <i className="ion-android-call" ></i>
                                        (+243) 990 490 945
                                    </li>
                                    
                                    <li>
                                        <i className="ion-android-mail" ></i>
                                        Email: info@asafuniversity.org
                                    </li>
                                </ul> */}
                            {/* </div> */}
                        {/* </div> */}
                    {/* </div> */}
                    <br></br>
                    

                </div>
                <div className="facultes teamc" >
                    
                    {/* <h1 >Notre équipe</h1> */}
                    <div className="allfaculty" >
                        <div className="fac">
                            <div className="singleFaculty" >
                                <img src={Imgg} className="e_img" />
                                <div className="dtl" >
                                    <h4>
                                        NGUNZA MUHINDO Johnny
                                        <p>{t('E_22')}</p>
                                    </h4>
                                    <p className="par" >
                                    {t('E_23')}
                                    </p>
                                </div>
                            </div>

                            
                              
                            <div className="singleFaculty" >
                                <img src={Lul} className="e_img" />
                                <div className="dtl" >
                                    <h4>
                                        LULIHI SAAMBA Bernard
                                        <p>{t('E_32')}</p>
                                    </h4>
                                    <p className="par">
                                    {t('E_33')}
                                    </p>
                                </div>
                            </div>
                              
                            <div className="singleFaculty" >
                                <img src={kas} className="e_img" />
                                <div className="dtl" >
                                    <h4>
                                        KASEREKA Kahuko Richard
                                        <p>{t('E_42')}</p>
                                    </h4>
                                    <p className="par" >
                                    {t('E_43')}

                                    </p>
                                </div>
                            </div>
                            <div className="singleFaculty" >
                                <img src={sak} className="e_img" />
                                <div className="dtl" >
                                    <h4>
                                        MWITO KAREKEZI Judith
                                        <p>{t('E_52')}</p>
                                    </h4>
                                    <p className="par" >
                                    {t('E_53')}
                                    </p>
                                </div>
                            </div>
                            <div className="singleFaculty" >
                                <img src={alb} className="e_img" />
                                <div className="dtl" >
                                    <h4>
                                        KASEREKA MITAVO Albéric
                                        <p>{t('E_62')}</p>
                                    </h4>
                                    <p className="par" >
                                    {t('E_63')}
                                    </p>
                                </div>
                            </div>
                            <div className="singleFaculty" >
                                <img src={Vincent} className="e_img" />
                                <div className="dtl" >
                                    <h4>
                                        PACIFIQUE MATUMO Vincent
                                        <p>{t('E_72')}</p>
                                    </h4>
                                    <p className="par" >
                                    {t('E_73')}
                                    </p>
                                </div>
                            </div>
                            <div className="singleFaculty" >
                                <img src={Robert} className="e_img" />
                                <div className="dtl" >
                                    <h4>
                                        LINDEZA MAMONI Robert
                                        <p>{t('E_82')}</p>
                                    </h4>
                                    <p className="par" >
                                    {t('E_83')}
                                    </p>
                                </div>
                            </div>
                              
                            
                            
                              
                        </div>   
                                 
                    </div>
                </div>
                </div>
                <div className="footerfacs" >
                    <Footer/>
                </div>
                
        </div>
    )
}

export default Equipe
