import React from 'react'
import Header from './shared/Header';
import Footer from './shared/Footer';
import "./Styles/Home.css";
import Img from './assets/pile-books-reading-getty-0918_horiz.jpg'
import Img1 from './assets/students_reading_online.jpg'
import Img2 from './assets/unnamed.jpg'
import Img3 from './assets/fd100bc73e_50147633_plane-te-technologie.jpg'
import Img4 from './assets/santiago-calatrava-architecture-07.jpg'
import ContexteI from './assets/run.jpg'
import { Map, GoogleApiWrapper } from 'google-maps-react';
import Slider from './shared/SliderRural';
import { Helmet } from 'react-helmet'
import Onglets from './shared/Onglets';
import { useTranslation } from 'react-i18next';

function Rurale() {
    const TITLE = "asAf Gallerie"
    const {t} = useTranslation()
    return (
        <div className="mainDiv di" >
            
        <Helmet>
        <title>{ TITLE }</title>
        </Helmet>
            <div className="header_rel" >
                <Header/>
            </div>
            <Slider/>
            <div className="galleryContainerContexte" >
                <div className="relativeGal" >
                    <img src={ContexteI} id="run" />
                    <div className="details_contexte" >
                    {t('R_1')}
<br/>
<br/>
{t('R_2')}

                    </div>
                    
                    {/* <Footer/> */}
                <Onglets/>
                </div>
                    {/* <Footer/> */}
            </div>
        </div>
    )
}

export default Rurale
