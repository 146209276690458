import React from 'react'
import Header from './shared/Header';
import Footer from './shared/Footer';
import "./Styles/Home.css";
import Img from './assets/pile-books-reading-getty-0918_horiz.jpg'
import Img1 from './assets/students_reading_online.jpg'
import Img2 from './assets/unnamed.jpg'
import Img3 from './assets/fd100bc73e_50147633_plane-te-technologie.jpg'
import Img4 from './assets/santiago-calatrava-architecture-07.jpg'
import ContexteI from './assets/croquis_nord_kivu.jpg'
import { Map, GoogleApiWrapper } from 'google-maps-react';
import Slider from './shared/SliderMilieu';
import { Helmet } from 'react-helmet'
import Onglets from './shared/Onglets';
import { useTranslation } from 'react-i18next';

function Milieu() {
    const TITLE = "asAf Gallerie"
    const {t} = useTranslation()
    return (
        <div className="mainDiv di" >
            
        <Helmet>
        <title>{ TITLE }</title>
        </Helmet>
            <div className="header_rel" >
                <Header/>
            </div>
            <Slider/>
            <div className="galleryContainerContexte" >
                <div className="relativeGal" >
                    <img src={ContexteI} />
                    <div className="details_contexte" >
                    <b><i>{t('M_1')}</i></b>
                    <br/>
                    <br/>
{t('M_2')}
<br/>{t('M_3')}
<br/>{t('M_4')}
<br/>{t('M_5')}
<br/>{t('M_6')}
<br/>{t('M_7')}

{/* <b><i>Population</i></b>
<b>Ville de Beni</b> : 771 476 habitants (Source : rapport annuel de l’administration de la ville de Beni, année 2018).
Territoire de Beni : 1 400 138 habitants (Source : tableau sy-noptique de la population congolaise et étrangère en territoire de Beni, année 2019). */}
<br/>
<br/>
<b>{t('M_8')}</b> : {t('M_81')}

{/* <i>Population moins de 20 ans</i>
<b>Ville de Beni</b> : 64 % (Source : rapport annuel de l’administration de la ville de Beni, année 2018). */}
<br/>
<br/>
<b>{t('M_9')}</b> {t('M_91')}
<br/>
<br/>
<b>{t('M_10')}</b> {t('M_101')}

{/* <i>Seuil de pauvreté</i> */}
<br/>
<b>{t('M_11')}</b> {t('M_111')}
<br/>
<br/>
<b>{t('M_12')}</b>{t('M_121')}
<br/>
<br/>
<b>{t('M_13')}</b> {t('M_131')}

{/* <i>Population agriculteurs </i> */}
<br/>
<b>{t('M_14')}</b> : {t('M_141')}
<br/>
<br/>
<b>{t('M_15')}</b> {t('M_151')}

<br/>
<br/>
<b>{t('M_16')} </b>{t('M_161')}
{/* <i>
Superficie</i> */}
<br/>
<b>{t('M_17')}</b> {t('M_171')}
<br/>
<br/>
<b>{t('M_18')}</b> {t('M_181')}
<br/>
<br/>

<b>{t('M_19')}</b> {t('M_191')}
<br/>


<i>
{t('M_20')}
</i>
<br/>
<br/>
{t('M_21')}
<br/>
<br/>

{t('M_22')}

<br/>
<br/>

<i>{t('M_23')}</i>
<br/>
{t('M_24')}
<br/>
<br/>
<i>{t('M_25')}</i>
<br/>
{t('M_26')}
<br/>
{t("M_27")}
<br/>
{t("M_28")}
<br/>
{t('M_29')}
<br/>
{t('M_30')}


{/* <i>
Autres détails importants qui nécessitent des enquêtes plus appro-fondies afin de ressortir des chiffres exacts dans le but de trouver des solutions idoines à ces différents problèmes :
</i> */}
<br/>
{t('M_31')}
<br/>

{t('M_32')}


                    </div>
                    
                    {/* <Footer/> */}
                <Onglets/>
                </div>
                    {/* <Footer/> */}
            </div>
        </div>
    )
}

export default Milieu
