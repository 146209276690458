import React from 'react'
import Header from './shared/Header';
import Footer from './shared/Footer';
import "./Styles/Home.css";
import Img from './assets/pile-books-reading-getty-0918_horiz.jpg'
import Test1 from './assets/test1.jpg'
import Test2 from './assets/test2.jpg'
import Milieu from './assets/rur.jpg'
import Rurale from './assets/20181029_101702.jpg'
import Bourses from './assets/burs.jpg'
import Organi from './assets/org.jpg'
import Context from "./assets/04_Afrique.jpg"
import Img3 from './assets/university-college-student-ss-1920.jpg'
import SliderAbout from './shared/SliderAbout';
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function About() {
    const TITLE = "asAf"
    const {t} = useTranslation();
    return (
        <div className="mainDiv di" >
            
        <Helmet>
        <title>{ TITLE }</title>
        </Helmet>
            <div className="header_rel" >
                <Header className="aboutHeader" />
            </div>
            <SliderAbout/>
            <div className="galleryContainerAbout" >
                <div className="relativeG relativeA" >
                    {/* <div className="about_v" >
                        <i className="ion-ios-lightbulb i" ></i>
                        <div className="moto" >
                            <h1>Autres son d'Afrique</h1>
                            <h3>
                            L’université asAf est aussi un promoteur de démocratie intellectuelle et culturelle, permettant l’expression des idées, favorisant l’émergence de courants culturels issus des préoccupations contemporaines des populations. Elle va orienter ses actions vers l’éducation permanente, le développement communautaire, la diversité culturelle dans le but de rechercher en permanence l’amélioration de la qualité de vie de l’ensemble des personnes qui peuplent le territoire où elle agit.
                            </h3>
                        </div>
                    </div> */}
                    <div className="goal" >
                        <Link to="/contexte" >
                        <div className="single_goal" >
                            {/* <div className="topGoal" > */}
                                {/* <i className="ion-ios-lightbulb" ></i> */}
                                <img src={Context} />
                                <h4>{t('A_1')}</h4>
                                {/* <h5>Former des femmes et des hommes qui vont concevoir la ville africaine.</h5> */}
                            {/* </div> */}
                        </div>
                        </Link>
                        
                        <Link to="/milieu" >
                        <div className="single_goal" >
                            
                            {/* <div className="topGoal" > */}
                            {/* <i className="ion-ios-lightbulb" ></i> */}
                                <img src={Milieu} />
                                <h4>{t('A_2')}</h4>
                                {/* <h5>Former des femmes et des hommes qui vont concevoir la ville africaine.</h5> */}
                            {/* </div> */}
                        </div>
                        </Link>

                        <Link to="/rurale" >
                        <div className="single_goal" >
                            
                            {/* <div className="topGoal" > */}
                            {/* <i className="ion-ios-lightbulb" ></i> */}
                                <img src={Rurale} />
                                <h4>{t('A_3')}</h4>
                                {/* <h5>Former des femmes et des hommes qui vont concevoir la ville africaine.</h5> */}
                            {/* </div> */}
                        </div>
                        </Link>
                    </div>

                    
                    <div className="goal" >
                        <Link to="/bourses" >
                        <div className="single_goal" >
                            
                            {/* <div className="topGoal" > */}
                            {/* <i className="ion-ios-lightbulb" ></i> */}
                                <img src={Bourses} />
                                <h4>{t('A_4')}</h4>
                                {/* <h5>Former des femmes et des hommes qui vont concevoir la ville africaine.</h5> */}
                            {/* </div> */}
                        </div>
                        </Link>
                        <div className="single_goal_testi" >
                            <div className="testii" >
                                <div className="singleTest" >
                                    <img src={Test1} />
                                    <div className="text_test" >
                                        <b>{t('A_5')}</b>
                                        <p>
                                        {t('A_6')}
    
                                        </p>
                                    </div>
                                </div>
                                <div className="singleTest" >
                                    <img src={Test2} />
                                    <div className="text_test" >
                                        <b>{t('A_7')}</b>
                                        <p>
                                        {t('A_8')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            
                            <h4>{t('A_9')}</h4>
                        </div>
                        
                        <div className="single_goal" >
                            
                            {/* <div className="topGoal" > */}
                            {/* <i className="ion-ios-lightbulb" ></i> */}
                                <img src={Organi} id="org" />
                                <h4>{t('A_10')}</h4>
                                {/* <h5>Former des femmes et des hommes qui vont concevoir la ville africaine.</h5> */}
                            {/* </div> */}
                        </div>
                    
                        
                    </div>                    
                <div>



                </div>
                
                </div>
                
                
                    
            </div>
                    <div className="equipe quip" >
                        
                        <h1>{t('A_11')}</h1>
                        <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.6721342279307!2d29.463701214168633!3d0.4904733996421613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1761abaaca18e3a5%3A0x6595ae59fac8931f!2sUniversit%C3%A9+autre+son+d&#39;Afrique+-+asAf!5e0!3m2!1sfr!2scd!4v1479827895629" frameborder="0" allowfullscreen></iframe>
                        <div className="campus_dtl" >
                            <div className="info" >
                                <h1>{t('A_12')}</h1>
                                <h4>{t('A_11')}</h4>
                                <div className="aboutDetails" >
                                    <ul>    
                                        <li>
                                            <i className="ion-android-globe" ></i>
                                            Beni (North-Kivu)
                                        </li>
                                                                        
                                        <li>
                                            <i className="ion-android-map" ></i>
                                            25 Avenue Sivirwa,
                                        </li>
                                        
                                        <li>
                                            <i className="ion-android-globe" ></i>
                                            {t('A_13')}
                                        </li>
                                        
                                        <li>
                                            <i className="ion-android-call" ></i>
                                            + 243 998 925 719
                                        </li>
                                        
                                        <li>
                                            <i className="ion-android-mail" ></i>
                                            Email: info@asafuniversity.org
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footing" >
                    <Footer/>
                    </div>
                    



                    
        </div>
    )
}

export default About
