import React from 'react'
import Header from './shared/Header';
import Slider from './shared/Slider';
import Content from './Content';
import Footer from './shared/Footer';
import "./Styles/Home.css";
import Img from './assets/pile-books-reading-getty-0918_horiz.jpg'
import Img1 from './assets/students_reading_online.jpg'
import Img2 from './assets/webinar.jpg'
import Img3 from './assets/university-college-student-ss-1920.jpg'

import { Helmet } from 'react-helmet'

function Home() {
    const TITLE = "asAf Accueil"
    return (
        <div className="mainDiv" >
            
            <Helmet>
            <title>{ TITLE }</title>
            </Helmet>
            <Header/>
            <Slider/>
            <Content/>
        </div>
    )
}

export default Home
