import React, { useState } from 'react';
import Img from '../components/assets/pile-books-reading-getty-0918_horiz.jpg'
import Img1 from '../components/assets/johny.jpg'
import Robert from '../components/assets/robert.jpeg'
import Lul from '../components/assets/lulihi.jpg'
import grity from '../components/assets/20181028_132525.jpg'
import Ed from '../components/assets/educ.jpg'
import Hum from '../components/assets/hum.jpg'
import dev from '../components/assets/integ.jpg'
import Fem from '../components/assets/WP_20171219_08_16_42_Pro.jpg'
import Footer from './shared/Footer';
import { useTranslation, Trans } from 'react-i18next';

function Content() {
    const [Values, setValues] = useState("");
    const handleValues = ()=>{
        if(Values===""){
            setValues("viewvalues")
        } else{
            setValues("");
        }
    }
    const {t} = useTranslation();
    return (
        <div className="content" >
            <div className="topcontentHome" >
                <h4>{t('Motto_title')}</h4>
                <h2>{t('Motto')}</h2>
            </div>
            <h5>
                {t('Motto_1')}
            </h5>
            <div className="values" >
                <div className="singleValue" >
                    <div className="valueText" >
                        <i className="ion-android-hangout" ></i>
                        <h4>Vision</h4>
                        <h5>
                        {t('Vision')}

                        </h5>
                    </div>
                </div>
                <div className="singleValue" >
                    <div className="valueText" >
                        <i className="ion-briefcase" ></i>
                        <h4>Mission</h4>
                        <h5>
                        {t('Mission')}
                        </h5>
                    </div>
                </div>
            </div>
            <div className="teachers" >
                <div className ="ourTeachers" onClick={handleValues} >
                    <h6>{t('Values_Title')}</h6>
                    <div className="allstaff" >
                        <div className="singleStaff" >
                            <img src={Hum} ></img>
                            <h6>{t('Humanity')}</h6>
                        </div>
                        <div className="singleStaff" >
                            <img src={grity} ></img>
                            <h6>{t('Integrity')}</h6>
                        </div>
                        <div className="singleStaff" >
                            <img src={dev} ></img>
                            <h6>{t('Durability')}</h6>
                        </div>
                        <div className="singleStaff" >
                            <img src={Ed} ></img>
                            <h6>{t('Education')}</h6>
                        </div>
                        <div className="singleStaff" >
                            <img src={Fem} ></img>
                            <h6>{t('Empowerment')}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="events" >
                <h1>{t('What')}</h1>
                <div>
                    <div className="singleEvent" >
                        <div className="img" >
                            <i className="ion-quote" ></i>
                        </div>
                        <div className="contentE" >
                            <p>{t('W_1')}</p>
                            <p>
                            <b>
                                <i>{t('W_11')}</i>
                                <br/>
                                Banjul<span>  </span> 
                            </b>
                             {t('W_12')}
                            <br/>
                            {t('W_121')}
                            </p>
                        </div>
                    </div>
                    
                    <div className="singleEvent" >
                        <div className="img" >
                            <i className="ion-quote" ></i>
                        </div>
                        <div className="contentE" >
                            <p>{t('W_2')}</p>
                            <p>
                            <b>
                            {t('W_21')}
                            </b>
                            </p>
                        </div>
                    </div>
                    
                    <div className="singleEvent" >
                        <div className="img" >
                            <i className="ion-quote" ></i>
                        </div>
                        <div className="contentE" >
                            <p>{t('W_3')}</p>
                            <p>
                            {t('W_31')}
                            <br/>
                            {t('W_32')}
                            </p>
                        </div>
                    </div>
                    
                    <div className="singleEvent" >
                        <div className="img" >
                            <i className="ion-quote" ></i>
                        </div>
                        <div className="contentE" >
                            <p>{t('W_4')}</p>
                            <p>
                            {t('W_41')}
                            <b>{t('W_42')}</b>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                {
                    Values!==""
                    ?
                    <div className="allValues_" >
                    <div className="value_" >
                        <h6 onClick={handleValues}>
                            <i className="ion-android-close" ></i>
                        </h6>
                        <div className="value_one_" >
                            <i className="ion-android-hangout" ></i>
                            <div className="value_dtl" >
                            <h5>{t('Humanity')}</h5>
                            <h4>
                            <i>{t('Val_1')} </i>
                            {t('Val_11')}

                            </h4>
                            </div>
                        </div>
                        <div className="value_one_" >
                            <i className="ion-android-hangout" ></i>
                            <div className="value_dtl" >
                            <h5>{t('Integrity')}</h5>
                            <h4>
                            <i>
                            {t('Val_2')}
<br/>
{/* Proverbes (livre de la Bible) 14 : 34 ‘’ La justice élève une nation…’’ */}
                            </i>
                            <br/>
                            <br/>
{t('Val_21')}

                            </h4>
                            </div>
                        </div>
                        <div className="value_one_" >
                            <i className="ion-android-hangout" ></i>
                            <div className="value_dtl" >
                            <h5>{t('Education')}</h5>
                            <h4>
                            <i>{t('Val_3')}</i>
                            <br/>
                            <br/>
                            <br/>
{t('Val_31')}

                            </h4>
                            </div>
                        </div>
                        <div className="value_one_" >
                            <i className="ion-android-hangout" ></i>
                            <div className="value_dtl" >
                            <h5>{t('Durability')}</h5>
                            <h4>
                            <i>{t('Val_4')} </i>
                                <br/>
{/* Genèse (livre de la Bible) 2: 15 ‘’ L’Eternel Dieu prit l’homme et le plaça dans le jardin d’Éden pour le cultiver et pour le garder.’’</i> */}
<br/>
<br/>
{t('Val_41')}

                            </h4>
                            </div>
                        </div>
                        <div className="value_one_" >
                            <i className="ion-android-hangout" ></i>
                            <div className="value_dtl" >
                            <h5>{t('Empowerment')}</h5>
                            <h4>
                            <i>{t('Val_5')}</i>
                            <br/>
                            <br/>
                            <br/>
{t('Val_51')}

                            </h4>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div></div>
                }
            <Footer/>
            {/* <div className="events join" >
                <h1>
                    Subscribe to our newsletter</h1>
                <div>
                    
                    <div className="form" >
                        <input type="text" placeholder = "email"/>
                        <button>Send</button>
                    </div>
                    
                    
                </div>
            </div> */}
        </div>
    )
}

export default Content
