import logo from './logo.svg';
import './App.css';
// import './components/asaf/styles/home.css'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

import './components/Styles/ionicons/css/ionicons.min.css';
// import "../i18n"

// import Home from "./components/asaf/Home";
import Home from "./components/Home";
import About from "./components/About";
import Academic from "./components/Academic";
import Gallery from './components/Gallery';
import History from './components/History';
import Equipe from './components/Equipe';

import Contexte from './components/Contexte';
import Milieu from './components/Milieu';
import Rurale from './components/Rurale';
import Bourses from './components/Bourses';
// import Temoignages from './components/';
import Organigramme from './components/Organigrame';
import { Helmet } from 'react-helmet'
import Tablogo from "./components/assets/logo.png"
import {useTranslation} from "react-i18next"


function App() {
  return (
    <div className="App">
    <Helmet>
      <link rel="icon" type="image/png" href={Tablogo} sizes="16x16" />
    </Helmet>
      <Router>
        <Switch>
          <Route path='/' exact >
            <Home/>
          </Route>
          <Route path='/historique' exact >
            <History/>
          </Route>
          <Route path='/facultés' exact >
            <Academic/>
          </Route>
          <Route path='/formation' exact >
            <Academic/>
          </Route>
          <Route path='/équipe' exact >
            <Equipe/>
          </Route>
          <Route path='/galerie' exact >
            <Gallery/>
          </Route>
          <Route path='/about' exact >
            <About/>
          </Route>

          
          <Route path='/contexte' exact >
            <Contexte/>
          </Route>
          <Route path='/milieu' exact >
            <Milieu/>
          </Route>
          <Route path='/rurale' exact >
            <Rurale/>
          </Route>
          <Route path='/bourses' exact >
            <Bourses/>
          </Route>
          <Route path='/temoignages' exact >
            {/* <Temoignages/> */}
          </Route>
          <Route path='/organigrame' exact >
            <Organigramme/>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
