import React from 'react';
import "../Styles/Home.css"
import Img from '../assets/oj.jpg'

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function SliderAbout() {
    const {t} = useTranslation();
    return (
        <div className="slider" >
            <img src={Img} alt='bg' className='slider_img' />
            <div className='relativeSlider' >
                <div className="textSlider" >
                    <div className="breakline" ></div>
                    <h1>{t('A_14')}</h1>
                    <h4>
                    {t('A_15')}
                    </h4>
                    <button>
                        <Link to="/">{t('Home')}</Link>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SliderAbout
