import React, { useState } from 'react'
import Header from './shared/Header';
import Footer from './shared/Footer';
import "./Styles/Home.css";
import Img from './assets/pile-books-reading-getty-0918_horiz.jpg'
import Img1 from './assets/students_reading_online.jpg'
import Img2 from './assets/unnamed.jpg'
import Img3 from './assets/fd100bc73e_50147633_plane-te-technologie.jpg'
import Img4 from './assets/santiago-calatrava-architecture-07.jpg'
import Img5 from './assets/urbanisme.jpg'
import { Map, GoogleApiWrapper } from 'google-maps-react';
import Slider from './shared/Slider';
import { Helmet } from 'react-helmet';


// carteinteractives
import one from "./assets/c01.jpg"
import two from "./assets/c02.jpg"
import three from "./assets/c03.jpg"
import four from "./assets/c04.jpg"
import five from "./assets/c05.jpg"

// cellules
import one1 from "./assets/ce2.jpg"
import one2 from "./assets/ce3.jpg"
import one3 from "./assets/ce4.jpg"
import one4 from "./assets/ce5.jpg"
import one5 from "./assets/ce6.jpg"
import one6 from "./assets/ce7.jpg"
import one7 from "./assets/ce8.jpg"
import one8 from "./assets/ce9.jpg"
import one9 from "./assets/ce10.jpg"
import one10 from "./assets/ce11.jpg"
import one11 from "./assets/ce12.jpg"
import one12 from "./assets/ce13.jpg"
import one13 from "./assets/ce14.jpg"

// conferences
import two1 from "./assets/co1.jpg"
import two2 from "./assets/co2.jpg"
import two3 from "./assets/co3.jpg"
import two4 from "./assets/co4.jpg"
import two5 from "./assets/co5.jpg"
import two6 from "./assets/co6.jpg"
import two7 from "./assets/co7.jpg"
import two8 from "./assets/co8.jpg"
import two9 from "./assets/co9.jpg"
import two10 from "./assets/co10.jpg"
import two11 from "./assets/co11.jpg"
import two12 from "./assets/co12.jpg"
import two13 from "./assets/co13.jpg"
import two14 from "./assets/co14.jpg"
import two15 from "./assets/co15.jpg"
import two16 from "./assets/co16.jpg"
import two17 from "./assets/co17.jpg"
import two18 from "./assets/co18.jpg"
import two19 from "./assets/co19.jpg"
import two20 from "./assets/co20.jpg"
import two21 from "./assets/co21.jpg"
import two22 from "./assets/co22.jpg"
import two23 from "./assets/co23.jpg"
import two24 from "./assets/co24.jpg"
import two25 from "./assets/co25.jpg"
import two26 from "./assets/co26.jpg"
import two27 from "./assets/co27.jpg"
import two28 from "./assets/co28.jpg"
import two29 from "./assets/co29.jpg"
import two30 from "./assets/co30.jpg"
import two31 from "./assets/co31.jpg"


// etudiants
import three1 from "./assets/e1.jpg"
import three2 from "./assets/e2.jpg"
import three3 from "./assets/e3.jpg"
import three4 from "./assets/e4.jpg"
import three5 from "./assets/e5.jpg"
import three6 from "./assets/e6.jpg"
import three7 from "./assets/e7.jpg"
import three8 from "./assets/e8.jpg"
import three9 from "./assets/e9.jpg"
import three10 from "./assets/e10.jpg"
import three11 from "./assets/e11.jpg"
import three12 from "./assets/e12.jpg"
import three13 from "./assets/e13.jpg"
import three14 from "./assets/e14.jpg"
import three15 from "./assets/e15.jpg"
import three16 from "./assets/e16.jpg"
import three17 from "./assets/e17.jpg"
import three18 from "./assets/e18.jpg"
import three19 from "./assets/e19.jpg"
import three20 from "./assets/e20.jpg"
import three21 from "./assets/e21.jpg"
import three22 from "./assets/e22.jpg"
import three23 from "./assets/e23.jpg"
import three24 from "./assets/e24.jpg"
import three25 from "./assets/e25.jpg"
import three26 from "./assets/e26.jpg"
import three27 from "./assets/e27.jpg"
import three28 from "./assets/e28.jpg"
import three29 from "./assets/e29.jpg"
import three30 from "./assets/e30.jpg"
import three31 from "./assets/e31.jpg"
import three32 from "./assets/e32.jpg"

// Johny
import four1 from "./assets/j1.jpg"
import four2 from "./assets/j2.jpg"
import four3 from "./assets/j3.jpg"
// import four4 from "./Johny/4.jpg"
import four5 from "./assets/j5.jpg"
import four6 from "./assets/j6.jpg"
import four7 from "./assets/j7.jpg"
import four8 from "./assets/j8.jpg"
import four9 from "./assets/j9.jpg"
import four10 from "./assets/j10.jpg"
import four11 from "./assets/j11.jpg"

// afriqueUrbain
import five1 from "./assets/a1.jpg"
import five2 from "./assets/a2.jpg"
import five3 from "./assets/a3.jpg"
import five4 from "./assets/a4.jpg"
import five5 from "./assets/a5.jpg"
import five6 from "./assets/a6.jpg"
import five7 from "./assets/a7.jpg"
import five8 from "./assets/a8.jpg"
import five9 from "./assets/a9.jpg"
import five10 from "./assets/a10.jpg"
import five11 from "./assets/a11.jpg"


// uasaf
import six1 from "./assets/u1.jpg"
import six2 from "./assets/u2.jpg"
import six3 from "./assets/u3.jpg"
import six4 from "./assets/u4.jpg"
import six5 from "./assets/u5.jpg"
import six6 from "./assets/u6.jpg"
import six7 from "./assets/u7.jpg"
import six8 from "./assets/u8.jpg"
import six9 from "./assets/u9.jpg"
import six10 from "./assets/u10.jpg"
import six11 from "./assets/u11.jpg"
import six12 from "./assets/u12.jpg"
import six13 from "./assets/u13.jpg"
import six14 from "./assets/u14.jpg"
import six15 from "./assets/u15.jpg"
import six16 from "./assets/u16.jpg"
import six17 from "./assets/u17.jpg"
import six18 from "./assets/u18.jpg"
import six19 from "./assets/u19.jpg"
import six20 from "./assets/u20.jpg"
import six21 from "./assets/u21.jpg"
import six22 from "./assets/u22.jpg"
import six23 from "./assets/u23.jpg"
import six24 from "./assets/u24.jpg"
import six25 from "./assets/u25.jpg"
import six26 from "./assets/u26.jpg"
import six27 from "./assets/u27.jpg"
import six28 from "./assets/u28.jpg"
import six29 from "./assets/u29.jpg"
import six30 from "./assets/u30.jpg"
import six31 from "./assets/u31.jpg"


// Pur
import seven1 from "./assets/p1.jpg"
import seven2 from "./assets/p2.jpg"
import seven3 from "./assets/p3.jpg"
import seven4 from "./assets/p4.jpg"
import seven5 from "./assets/p5.jpg"
import seven6 from "./assets/p6.jpg"
import seven7 from "./assets/p7.jpg"
import seven8 from "./assets/p8.jpg"
import seven9 from "./assets/p9.jpg"
import seven10 from "./assets/p10.jpg"
import seven11 from "./assets/p11.jpg"
import seven12 from "./assets/p12.jpg"
import seven13 from "./assets/p13.jpg"
import seven14 from "./assets/p14.jpg"
import seven15 from "./assets/p15.jpg"
import seven16 from "./assets/p16.jpg"
import seven17 from "./assets/p17.jpg"
import seven18 from "./assets/p18.jpg"
import seven19 from "./assets/p19.jpg"
import seven20 from "./assets/p20.jpg"
import seven21 from "./assets/p21.jpg"
import seven22 from "./assets/p22.jpg"
import seven23 from "./assets/p23.jpg"
import seven24 from "./assets/p24.jpg"
import seven25 from "./assets/p25.jpg"
import seven26 from "./assets/p26.jpg"
import seven27 from "./assets/p27.jpg"
import seven28 from "./assets/p28.jpg"
import seven29 from "./assets/p29.jpg"
import seven30 from "./assets/p30.jpg"
import seven31 from "./assets/p31.jpg"
import seven32 from "./assets/p32.jpg"
import seven33 from "./assets/p33.jpg"
import seven34 from "./assets/p34.jpg"
import seven35 from "./assets/p35.jpg"
import seven36 from "./assets/p36.jpg"
import seven37 from "./assets/p37.jpg"
import seven38 from "./assets/p38.jpg"
import seven39 from "./assets/p39.jpg"
import seven40 from "./assets/p40.jpg"
import seven41 from "./assets/p41.jpg"
import seven42 from "./assets/p42.jpg"
import seven43 from "./assets/p43.jpg"
import seven44 from "./assets/p44.jpg"
import seven45 from "./assets/p45.jpg"
import seven46 from "./assets/p46.jpg"
import seven47 from "./assets/p47.jpg"
import seven48 from "./assets/p48.jpg"
import seven49 from "./assets/p49.jpg"
import seven50 from "./assets/p50.jpg"
import seven51 from "./assets/p51.jpg"
import { useTranslation } from 'react-i18next';


function Gallery() {

    const [ClickedUl, setClickedUl] = useState(null);

    const cartes = [
        {"name":one},
        {"name":two},
        {"name":three},
        {"name":four},
        {"name":five},
    ]
    const johny = [
        {"name":four1},
        {"name":four2},
        {"name":four3},
        {"name":four5},
        {"name":four6},
        {"name":four7},
        {"name":four8},
        {"name":four9},
        {"name":four10},
        {"name":four11},
    ]
    const afrique = [
        {"name":five1},
        {"name":five2},
        {"name":five3},
        {"name":five4},
        {"name":five5},
        {"name":five6},
        {"name":five7},
        {"name":five8},
        {"name":five9},
        {"name":five10},
        {"name":five11},
    ]
    const cellules = [
        {"name":one1},
        {"name":one2},
        {"name":one3},
        {"name":one4},
        {"name":one5},
        {"name":one6},
        {"name":one7},
        {"name":one8},
        {"name":one9},
        {"name":one10},
        {"name":one11},
        {"name":one12},
        {"name":one13}
    ]
    const conferences = [
        {"name":two1},
        {"name":two2},
        {"name":two3},
        {"name":two4},
        {"name":two5},
        {"name":two6},
        {"name":two7},
        {"name":two8},
        {"name":two9},
        {"name":two10},
        {"name":two11},
        {"name":two12},
        {"name":two13},
        {"name":two14},
        {"name":two15},
        {"name":two16},
        {"name":two17},
        {"name":two18},
        {"name":two19},
        {"name":two20},
        {"name":two21},
        {"name":two22},
        {"name":two23},
        {"name":two24},
        {"name":two25},
        {"name":two26},
        {"name":two27},
        {"name":two28},
        {"name":two29},
        {"name":two30},
        {"name":two31}
    ]
    const uasaf = [
        {"name":six1},
        {"name":six2},
        {"name":six3},
        {"name":six4},
        {"name":six5},
        {"name":six6},
        {"name":six7},
        {"name":six8},
        {"name":six9},
        {"name":six10},
        {"name":six11},
        {"name":six12},
        {"name":six13},
        {"name":six14},
        {"name":six15},
        {"name":six16},
        {"name":six17},
        {"name":six18},
        {"name":six19},
        {"name":six20},
        {"name":six21},
        {"name":six22},
        {"name":six23},
        {"name":six24},
        {"name":six25},
        {"name":six26},
        {"name":six27},
        {"name":six28},
        {"name":six29},
        {"name":six30},
        {"name":six31}
    ]

    
    const programme = [
        {"name":seven1},
        {"name":seven2},
        {"name":seven3},
        {"name":seven4},
        {"name":seven5},
        {"name":seven6},
        {"name":seven7},
        {"name":seven8},
        {"name":seven9},
        {"name":seven10},
        {"name":seven11},
        {"name":seven12},
        {"name":seven13},
        {"name":seven14},
        {"name":seven15},
        {"name":seven16},
        {"name":seven17},
        {"name":seven18},
        {"name":seven19},
        {"name":seven20},
        {"name":seven21},
        {"name":seven22},
        {"name":seven23},
        {"name":seven24},
        {"name":seven25},
        {"name":seven26},
        {"name":seven27},
        {"name":seven28},
        {"name":seven29},
        {"name":seven30},
        {"name":seven31},
        {"name":seven32},
        {"name":seven33},
        {"name":seven34},
        {"name":seven35},
        {"name":seven36},
        {"name":seven37},
        {"name":seven38},
        {"name":seven39},
        {"name":seven40},
        {"name":seven41},
        {"name":seven42},
        {"name":seven43},
        {"name":seven44},
        {"name":seven45},
        {"name":seven46},
        {"name":seven47},
        {"name":seven48},
        {"name":seven49},
        {"name":seven50},
        {"name":seven51}
    ]

    const etudiants=[
        {"name":three1},
        {"name":three2},
        {"name":three3},
        {"name":three4},
        {"name":three5},
        {"name":three6},
        {"name":three7},
        {"name":three8},
        {"name":three9},
        {"name":three11},
        {"name":three12},
        {"name":three13},
        {"name":three14},
        {"name":three15},
        {"name":three16},
        {"name":three17},
        {"name":three18},
        {"name":three19},
        {"name":three21},
        {"name":three22},
        {"name":three23},
        {"name":three24},
        {"name":three25},
        {"name":three26},
        {"name":three27},
        {"name":three28},
        {"name":three29},
        {"name":three30},
        {"name":three31},
        {"name":three32}
    ]

    const TITLE = "asAf Gallerie"
    const{t} = useTranslation();
    return (
        <div className="mainDiv di" >
            
        <Helmet>
        <title>{ TITLE }</title>
        </Helmet>
            <div className="header_rel" >
                <Header/>
            </div>
            <Slider/>
            <div className="galleryContainer" >
                <div className="relativeG" >
                    <ul>
                        <li onClick={()=>setClickedUl("cartes")} >{t('G_1')}</li>
                        <li onClick={()=>setClickedUl("cellules")} >{t('G_2')}</li>
                        <li onClick={()=>setClickedUl("conferences")} >{t('G_3')}</li>
                        <li onClick={()=>setClickedUl("etudiants")} >{t('G_4')}</li>
                        <li onClick={()=>setClickedUl("johny")} >{t('G_5')}</li>
                        <li onClick={()=>setClickedUl("afrique")} >{t('G_6')}</li>
                        <li onClick={()=>setClickedUl("uasaf")} >{t('G_7')}</li>
                        <li onClick={()=>setClickedUl("programme")} >{t('G_8')}</li>
                    </ul>
                    <div className="eventsGal" >
                        {
                            ClickedUl===null
                            ?
                            cartes.map((item)=>(
                                    <div className="gallerySingle" >
                                        <img src={item.name} className="e_img" />
                                        <div className="event_dtl_" >
                                        </div>
                                    </div>
                                ))
                            :
                            ClickedUl==="cartes"
                            ?
                            cartes.map((item)=>(
                                <div className="gallerySingle" >
                                    <img src={item.name} className="e_img" />
                                    <div className="event_dtl_" >
                                    </div>
                                </div>
                            ))
                            :
                            ClickedUl==="cellules"
                            ?
                            cellules.map((item)=>(
                                <div className="gallerySingle" >
                                    <img src={item.name} className="e_img" />
                                    <div className="event_dtl_" >
                                    </div>
                                </div>
                            ))
                            :
                            ClickedUl==="conferences"
                            ?
                            conferences.map((item)=>(
                                <div className="gallerySingle" >
                                    <img src={item.name} className="e_img" />
                                    <div className="event_dtl_" >
                                    </div>
                                </div>
                            ))
                            :
                            
                            ClickedUl==="etudiants"
                            ?
                            etudiants.map((item)=>(
                                <div className="gallerySingle" >
                                    <img src={item.name} className="e_img" />
                                    <div className="event_dtl_" >
                                    </div>
                                </div>
                            ))
                            :
                           
                            ClickedUl==="johny"
                            ?
                            johny.map((item)=>(
                                <div className="gallerySingle" >
                                    <img src={item.name} className="e_img" />
                                    <div className="event_dtl_" >
                                    </div>
                                </div>
                            ))
                            :
                            
                            ClickedUl==="afrique"
                            ?
                            afrique.map((item)=>(
                                <div className="gallerySingle" >
                                    <img src={item.name} className="e_img" />
                                    <div className="event_dtl_" >
                                    </div>
                                </div>
                            ))
                            :
                            
                            ClickedUl==="uasaf"
                            ?
                            uasaf.map((item)=>(
                                <div className="gallerySingle" >
                                    <img src={item.name} className="e_img" />
                                    <div className="event_dtl_" >
                                    </div>
                                </div>
                            ))
                            :
                            
                            ClickedUl==="programme"
                            ?
                            programme.map((item)=>(
                                <div className="gallerySingle" >
                                    <img src={item.name} className="e_img" />
                                    <div className="event_dtl_" >
                                    </div>
                                </div>
                            ))
                            :
                            cartes.map((item)=>(
                                <div className="gallerySingle" >
                                    <img src={item.name} className="e_img" />
                                    <div className="event_dtl_" >
                                    </div>
                                </div>
                            ))
                        }               
                    </div>
                </div>
                    {/* <Footer/> */}
            </div>
        </div>
    )
}

export default Gallery
