import React, { useState } from 'react';
import "../Styles/Home.css";
import { Link } from 'react-router-dom';
import Logo from '../assets/logo.png'
import {useTranslation} from "react-i18next"

function Header() {
    const [MyMap, setMyMap] = useState("");
    const handleCloseMap = ()=>{
        if(MyMap==="")
        {
            setMyMap("map")
        } else{
            setMyMap("")
        }
    }
    const [mMenu, setmMenu] = useState(null);
    const {t, i18n} = useTranslation();
    const handleMenu = ()=>{
        if(!mMenu){
            setmMenu(true)
        } else{
            setmMenu(null)
        }
    }
    return (
        <div className="header" >
            {
                mMenu
                ?
                <div className="nav_click" >
                    <ul>
                        <li>
                            <Link to="/">{t('Home')}</Link>
                        </li>
                        <li>
                            <Link to="/historique">{t('History')}</Link>
                        </li>
                        <li>
                            <Link to="/facultés">{t('Faculty')}</Link>
                        </li>
                        <li>
                            <Link to="/équipe">{t('Team')}</Link>
                        </li>
                        <li>
                            <Link to="/galerie">{t('Gallery')}</Link>
                        </li>
                        <li>

                            <span onClick={()=>i18n.changeLanguage("fr-FR")} style={{ color:'blue', marginTop:'0px' }} >FR |</span>
                            <span onClick={()=>i18n.changeLanguage("en-US")} style={{ color:'red', marginRight:10, marginTop:'-10px' }} > EN</span>
                        </li>
                    </ul>
                </div>
                :
                <div></div>
            }
            
            <div className="header1">
                <Link to="/" >
                <img src={Logo} />
                <h1>asAfUniversity</h1>
                </Link>
                <ul>
                    <li>
                        <i className="ion-android-mail" ></i>
                        info@asafuniversity.com
                    </li>
                    <li>
                        <i className="ion-android-call" ></i>
                        + 243 998 925 719 | +243 827 910650
                    </li>
                    <li onClick={handleCloseMap}>
                        <i className="ion-android-map" ></i>
                        Beni
                    </li>
                    <li>
                        <Link to={{ pathname: "http://50.87.206.220:2095" }} target="_blank" >webmail</Link>
                    </li>
                    <ol  >
                        <i className="ion-android-menu" onClick={handleMenu} ></i>
                    </ol>
                </ul>
            </div>
            <div className="header2">
                <ul>
                    <li>
                        <Link to="/">{t('Home')}</Link>
                    </li>
                    <li>
                        <Link to="/historique">{t('History')}</Link>
                    </li>
                    <li>
                        <Link to="/facultés">{t('Faculty')}</Link>
                    </li>
                    <li>
                        <Link to="/équipe">{t('Team')}</Link>
                    </li>
                    <li>
                        <Link to="/galerie">{t('Gallery')}</Link>
                    </li>
                </ul>
                <ul>
                    {/* <li><i className="ion-social-twitter" ></i></li>
                    <li><i className="ion-social-instagram" ></i></li>
                    <li><i className="ion-social-facebook" ></i></li> */}
                    <li style={{ marginTop:'10px', fontSize:"14px", cursor:'pointer' }} >
                        <span onClick={()=>i18n.changeLanguage("fr-FR")} style={{ color:'blue' }} >FR |</span>
                        <span onClick={()=>i18n.changeLanguage("en-US")} style={{ color:'red' }} > EN</span>
                    </li>
                </ul>
                
            </div>
            {
                MyMap===""
                ?<div></div>
                :
                <div className="location_content" >
                    <div className="beni_location" >
                        <h6>
                            <i className="ion-android-close" onClick={handleCloseMap} ></i>
                        </h6>
                        <iframe className="map_full" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.6721342279307!2d29.463701214168633!3d0.4904733996421613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1761abaaca18e3a5%3A0x6595ae59fac8931f!2sUniversit%C3%A9+autre+son+d&#39;Afrique+-+asAf!5e0!3m2!1sfr!2scd!4v1479827895629" frameborder="0" allowfullscreen></iframe>
                    </div>
                </div>
            }
        </div>
    )
}

export default Header
