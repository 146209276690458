import React from 'react'
import Header from './shared/Header';
import Footer from './shared/Footer';
import "./Styles/Home.css";
import Img from './assets/pile-books-reading-getty-0918_horiz.jpg'
import Img1 from './assets/students_reading_online.jpg'
import Img2 from './assets/unnamed.jpg'
import Img3 from './assets/fd100bc73e_50147633_plane-te-technologie.jpg'
import Img4 from './assets/santiago-calatrava-architecture-07.jpg'
import ContexteI from './assets/04_Afrique.jpg'
import { Map, GoogleApiWrapper } from 'google-maps-react';
import Slider from './shared/Slider';
import { Helmet } from 'react-helmet'

function Organigrame() {
    const TITLE = "asAf Gallerie"
    return (
        <div className="mainDiv di" >
            
        <Helmet>
        <title>{ TITLE }</title>
        </Helmet>
            <div className="header_rel" >
                <Header/>
            </div>
            <Slider/>
            <div className="galleryContainerContexte" >
                <div className="relativeGal" >
                    <img src={ContexteI} />
                    <div className="details_contexte" >
                    En plus de l’historique du concept « autre son d’Afrique » qui remonte au début des années 2000, les premières idées qui ont poussé à la création de l’Université asAf font références à la thématique de la ville africaine. L’ensemble des problèmes qui se posent sur ce sujet (problématique) peut se résumer en cette question : <b>Demain, quelle ville pour l’Afrique ?</b>
                    <br/>
                    <br/>
L’Afrique est encore un continent rural, c’est-à-dire qu’on y trouve encore à l’heure actuelle beaucoup plus de ruraux que de citadins. Mais c’est le continent qui s’urbanise rapidement. Les africains investissent de plus en plus les villes. Selon les prévi-sions de l’UN-Habitat, il y aura 55% d’urbains en 2030 et plus de 60% en 2050 ; c’est bientôt. Aujourd’hui, comment préparer cette transition urbaine ? En tant que pays, ville, universités ou centres de recherches ? Comment nous africains on s’y prépare ?
<br/>
<br/>
Dans cette suite de questionnements sur la ville africaine de demain, le promoteur de cette institution s’était interrogé sur le rôle que pouvait jouer une institution d’enseignement supérieur dans le contexte de transition urbaine en Afrique. Partant, plu-sieurs idées, qui devaient se concrétiser en objectifs à atteindre, avaient émergé. Parmi ces idées, nous pouvons noter une série de propositions sur le rôle que peut jouer une université de nos jours ; entre autres, une Université :
<br/>-	Qui est conçue pour être un pôle de réflexion sur la ville africaine.
<br/>-	Comme facteur de développement de son milieu.
<br/>-	Qui prône une éducation différente.
<br/>-	Qui fonctionne selon l’éthique chrétienne.
<br/>-	Qui soit une petite ou une moyenne structure mais effi-ciente.
<br/>-	Qui s’intéresse aux différentes questions de conserva-tion /préservation de la nature et de l’environnement ; aux questions de développement durable et aux enjeux mondiaux sur le changement climatique.
<br/>-	Qui assure le développement intellectuel et socioculturel de son territoire.
<br/>-	Qui soit innovante.
<br/>-	Qui propose des outils de création, d’expression et de communication. 
<br/>-	Qui prône un dialogue franc et constructif entre les cul-tures.
<br/>-	Qui développe des projets qui permettent une participa-tion active des citoyens, avec une attention particulière aux personnes les plus défavorisées.
<br/>-	Qui va orienter ses actions vers l'éducation permanente et le développement communautaire.
<br/>
<br/>
Le but étant de rechercher en permanence l’amélioration de la qualité de vie de l’ensemble des personnes qui peuplent le ter-ritoire où elle agit.
<br/><br/>
Sur ce, la vision définie pour ce projet a été énoncée dans ce sens : <b>Réinventer la ville africaine</b>. L’Université asAf a l’ambition de réinventer* la ville africaine en général et la ville congolaise en particulier par la formation** et d’apporter ainsi sa contribution pour trouver des réponses adéquates aux besoins des populations de ce continent (l’Afrique) qui est en train de vivre sa transition urbaine.

                    </div>
                </div>
                    {/* <Footer/> */}
            </div>
        </div>
    )
}

export default Organigrame
