import React from 'react'
import Header from './shared/Header';
import Footer from './shared/Footer';
import "./Styles/Home.css";
import Img from './assets/pile-books-reading-getty-0918_horiz.jpg'
import Img1 from './assets/3713152.jpg'
import Img2 from './assets/unnamed.jpg'
import Img3 from './assets/fd100bc73e_50147633_plane-te-technologie.jpg'
import Img4 from './assets/med.jpg'
import Img5 from './assets/urbanisme.jpg'
import { Map, GoogleApiWrapper } from 'google-maps-react';
import SliderFac from './shared/SliderFac';
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next';

function Academic() {
    const TITLE = "asAf Facultés"
    const {t} = useTranslation();
    return (
        <div className="mainDiv" >
            
        <Helmet>
        <title>{ TITLE }</title>
        </Helmet>
            <div className="header_rel" >
                <Header/>
            </div>
            <SliderFac/>
            <div className="facultyContent" >
                <div className="one" >
                    <div className="aboutSection" >
                        {/* <i className="ion-lightbulb" ></i> */}
                        {/* <h3>
                        <h1>Autres son d'Afrique</h1>
                        

                        </h3> */}
                    </div>

                    

                    {/* <div className="Campus" > */}
                        {/* <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.6721342279307!2d29.463701214168633!3d0.4904733996421613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1761abaaca18e3a5%3A0x6595ae59fac8931f!2sUniversit%C3%A9+autre+son+d&#39;Afrique+-+asAf!5e0!3m2!1sfr!2scd!4v1479827895629" frameborder="0" allowfullscreen></iframe> */}
                        {/* <div className="campus_dtl" > */}
                            {/* <div className="info" > */}
                                {/* <h1>asAf - autre son d'Afrique</h1>
                                <h4>Localisation</h4> */}
                                {/* <ul>    
                                    <li>
                                        <i className="ion-android-globe" ></i>
                                        Beni (North-Kivu)
                                    </li>
                                                                    
                                    <li>
                                        <i className="ion-android-map" ></i>
                                        25 Avenue Sivirwa,
                                    </li>
                                    
                                    <li>
                                        <i className="ion-android-globe" ></i>
                                        République Démocratique du Congo
                                    </li>
                                    
                                    <li>
                                        <i className="ion-android-call" ></i>
                                        (+243) 990 490 945
                                    </li>
                                    
                                    <li>
                                        <i className="ion-android-mail" ></i>
                                        Email: info@asafuniversity.org
                                    </li>
                                </ul> */}
                            {/* </div> */}
                        {/* </div> */}
                    {/* </div> */}
                    <br></br>
                    

                </div>
                <div className="facultes" >
                    <div className="myfac fac_m" >
                    <div className="leftFaculty" >
                        
                        <h2 >{t('F_h3')}</h2>
                        <div className="allfaculty_" >
                            <div className="fac">
                                <div className="singleFaculty_" >
                                    <img src={Img2} className="e_img" />
                                    <div className="dtl" >
                                        <h4>{t('F_h3_1')}</h4>
                                        <ul>
                                            <li>{t('F_h3_11')}</li>
                                            <li>{t('F_h3_12')}</li>
                                            <li>{t('F_h3_13')} </li>
                                            <li>{t('F_h3_14')}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="singleFaculty_ fleft" >
                                    <div className="dtl" >
                                        <h4>{t('F_h3_2')}</h4>
                                        <ul>
                                            <li>{t('F_h3_21')}</li>
                                            <li>{t('F_h3_22')}</li>
                                            <li>{t('F_h3_23')}</li>
                                            <li>{t('F_h3_24')}</li>
                                        </ul>
                                    </div>
                                    <img src={Img1} className="e_img" />
                                </div>
                                <div className="singleFaculty_" >
                                    <img src={Img5} className="e_img" />
                                    <div className="dtl" >
                                        <h4>{t('F_h3_3')}</h4>
                                        <ul>
                                            <li>{t('F_h3_31')}</li>
                                            <li>{t('F_h3_32')}</li>
                                        </ul>
                                    </div>
                                </div>  
                                <div className="singleFaculty_ fleft" >
                                    <div className="dtl" >
                                        <h4>{t('F_h3_4')}</h4>
                                        
                                        <ul>
                                            <li>{t('F_h3_41')} </li>
                                            <li>{t('F_h3_42')}</li>
                                        </ul>
                                    </div>
                                    <img src={Img3} className="e_img" />
                                </div> 
                                <div className="singleFaculty_" >
                                    <img src={Img4} className="e_img" />
                                    <div className="dtl" >
                                        <h4>{t('F_h3_5')}</h4>
                                        <ul>
                                            <li>{t('F_h3_51')} </li>
                                            <li>{t('F_h3_52')}</li>
                                        </ul>
                                    </div>
                                </div>    
                            </div>   
                                     
                        </div>
                        </div>
                        <div className="rightFaculty" >
                            <h2>{t('F_h1_1')}</h2>
                            <ul>
                                <li><b>{t('F_h2_1')}</b></li>
                                {/* <p>Notre formation tourne autour des domaines ci-après :</p> */}
                                <div className="ol" >
                                    <p>
                                    {t('F_h2_11')}
                                    </p>
                                </div>
                            </ul>
                            <ul>
                                <li><b>{t('F_h2_2')}</b></li>
                                <div className="ol" >
                                    <p>{t('F_h2_22')}</p>
                                </div>
                            </ul>
                            <ul>
                                <li>
                                    <b>{t('F_h2_3')}</b>
                                </li>
                                <li>{t('F_h2_3_1')}</li>
                                <div className="ol" >
                                    <p>{t('F_h2_3_11')}</p>
                                </div>
                                <li>{t('F_h2_3_2')}</li>
                                <div className="ol" >
                                    <p>{t('F_h2_3_2_1')}</p>
                                    <p>{t('F_h2_3_2_2')}</p>
                                    <p>{t('F_h2_3_2_3')}</p>
                                </div>
                                <li>{t('F_h2_3_3')}</li>
                                <div className="ol" >
                                    <p>{t('F_h2_3_2_1')}</p>
                                    <p>{t('F_h2_3_2_2')}</p>
                                    <p>{t('F_h2_3_2_3')}</p>
                                </div>
                                <li>Les Réseaux asAf pour l’entrepreneuriat dans la ville en Afrique </li>
                                <div className="ol" >
                                    <p>{t('F_h2_3_2_1')}</p>
                                    <p>{t('F_h2_3_2_2')}</p>
                                    <p>{t('F_h2_3_2_3')}</p>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
                </div>
                <div className="footerfac" >
                    <Footer/>
                </div>
                
        </div>
    )
}

export default Academic
