import React from 'react';
import "../Styles/Home.css"
import Img from '../assets/oj.jpg'

import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next"

function SliderFac() {
    const {t} = useTranslation()
    return (
        <div className="slider" >
            <img src={Img} alt='bg' className='slider_img' />
            <div className='relativeSlider' >
                <div className="textSlider" >
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <div className="breakline" ></div>
                    <h1>{t("G_6")}</h1>
                    
                </div>
            </div>
        </div>
    )
}

export default SliderFac
