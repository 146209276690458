import React from 'react';
import "../Styles/Home.css"
import Img from '../assets/oj.jpg'

import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next"

function Slider() {
const {t} = useTranslation();
    return (
        <div className="slider" >
            <img src={Img} alt='bg' className='slider_img' />
            <div className='relativeSlider' >
                <div className="textSlider" >
                    <br/>
                    <br/>
                    <div className="breakline" ></div>
                    <h1>{t('H_h1')}</h1>
                    <button>
                        <Link to="/about">{t('More')}</Link>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Slider
