import React from 'react';
import "../Styles/Home.css"
import Img from '../assets/oj.jpg'

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function SliderTeam() {
const {t} = useTranslation();
    return (
        <div className="slider" >
            <img src={Img} alt='bg' className='slider_img' />
            <div className='relativeSlider' >
                <div className="textSlider" >
                    <div className="breakline" ></div>
                    <h1>{t('E_1')}</h1>
                    {/* <h4>
                    Connaitre les besoins vitaux de l’homme dans la ville. L’orientation  et la création des différentes filières sont définies en fonction des besoins vitaux de l’homme pour un développement durable. 
La ville ne doit pas seulement être faite de béton et d’acier... L’homme doit être au centre de la conception de nos villes de demain.
                    </h4> */}
                    <button>
                        <Link to="/about">{t('More')}</Link>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SliderTeam
