import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import React from "react";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
const resources = {
  en: {
    translation: {
      "Home": "Home",
      "History":'History',
      "Faculty":"Faculty",
      "Team":'Our Team',
      "Gallery":"Gallery",
      "Heading":'Other sound from Africa University',
      "More":"More",
      "H1":"PROPOSE OTHER SOUNDS IN THE SERVICE OF PEACE AND DEVELOPMENT OF THE GREAT LAKES REGION IN AFRICA, FAR FROM THE IDEA OF BEING THE BEST, BUT SIMPLY BE ANOTHER BELL SOUND THAT WILL BRING MORE TO THE DEVELOPMENT OF THE AFRICAN CONTINENT",
      "Motto_title":"Our motto",
      "Motto":"educate differently",
      "Motto_1":"The other sound of Africa University, in acronym UasAf, is a private higher education institution. This institution is officially recognized by the Ministry of Higher and University Education through decree N 058 of February 14, 2019.",
      "Vision":"To be a center of excellence in the thinking and design of the African city.",
      "Mission":"Train women and men who will design the African city.",
      "Values_Title":"Our values",
      "Integrity":"Integrity",
      "Humanity":"Humanity",
      "Durability":"SUSTAINABLE (SUSTAINABLE DEVELOPMENT AND ENVIRONMENTAL PROTECTION)",
      "Education":"Education For All",
      "Empowerment":"WOMEN'S EMPOWERMENT",
      "What":"News",
      "W_Title1":"AFRICA MY CONTINENT",
      // historique
      "H_h1":"Power of a dream",
      "H_h2":"The year 2000 and the birth of a concept ...",
      "H_h3":"In 1998, I put my suitcases in Rabat, Morocco to continue my studies. It was in this country that I got to know the University Biblical Group (GBU), a movement made up of international students and part of the IFES * family. I joined this movement that year and have been a part of it to this day.",
      "H_h3_1":"From this movement, I drew an important thought that accompanied me throughout my academic career: As a Christian, I am called to contribute significantly to the development of my country. It was in the year 2000, after a meeting of musicians in which I had taken part, that the concept of 'another sound from Africa' ​​sprang up in me. It was a summer evening in Casablanca, I remember like it was yesterday, after this meeting I asked myself these three questions throughout dinner:",
      "H_h3_2":"Why are the sounds I hear from Africa, especially my Great Lakes region, often those of guns?",
      "H_h3_3":"As a student, artist and future architect, what other sounds can I suggest so that we no longer reflect these horrific images?",
      "H_h3_4":"What can culture and education contribute to the development of the African continent and to its pacification?",
      "H_h4":"I had a dream that day ...",
      "H_h5":"That of promoting education and culture in the Great Lakes region of Africa, more precisely in the east of the Democratic Republic of Congo. Propose other sounds in the service of peace and development in the Great Lakes region.",
      "H_h5_1":"The concept of 'another sound of Africa' ​​had just been born, far from it the idea of ​​being the best, but quite simply another sound of the bell which will bring more to the development of the African continent.",
      "H_h5_2":"Promoter of the asAf University",
      "H_h6":"The dream becomes reality",
      "H_h7":"16 YEARS AFTER THE CASABLANCA CALL ...",
      "H_h8_1":"Sixteen years after the Casablanca call, the University of the Other Sound of Africa, abbreviated as 'UasAf', was founded in the city of Beni in the eastern part of the Democratic Republic of Congo.",
      "H_h8_2":"asAf-U is a private higher education institution.",
      "H_h8_3":"This institution is officially recognized by the Ministry of Higher and University Education through Order No. 058 of February 14, 2019.",
      "H_h9":"MAKE A DONATION",
      "H_h10":"Send",
      "H_h11":"By completing this form, you will allow the administration to contact you for any donation you would like to make for Université asAf",

      // Facultes
      "F_h1":"Man in town",
      "F_h1_1":"OUR TRAINING MODEL",
      "F_h2_1":"I. Our four areas of intervention in the African city",
      "F_h2_11":"Food, Health, Housing & Infrastructures and NICT",
      "F_h2_2":"II. Bilingual training (French-English)",
      "F_h2_22":"Part of the training will be in English (planned).",
      "F_h2_3":"III. Our “Training Package”",
      "F_h2_3_1":"Official program",
      "F_h2_3_11":"Knowledge transfer",
      "F_h2_3_2":"Innovation workshops on the African city",
      "F_h2_3_2_1":"Design and skills acquisition",
      "F_h2_3_2_2":"Training and Learning",
      "F_h2_3_2_3":"Writing of projects and innovations",
      "F_h2_3_3":"Research and Publication on the city in Africa",
      "F_h2_3_3_1":"Design and skills acquisition",
      "F_h2_3_3_2":"Training and Learning",
      "F_h2_3_3_3":"Writing of projects and innovations",
      "F_h2_3_4":"AsAf Networks for entrepreneurship in the city in Africa",
      "F_h2_3_4_1":"Design and skills acquisition",
      "F_h2_3_4_2":"Training and Learning",
      "F_h2_3_4_3":"Writing of projects and innovations",
      "F_h3":"OUR FACULTIES & DEPARTMENTS",
      "F_h3_1":"AGRONOMIC SCIENCES AND RURAL ENGINEERING",
      "F_h3_2":"SCIENCES AND TECHNOLOGIES APPLIED TO THE ENVIRONMENT",
      "F_h3_3":"BIOCLIMATIC ARCHITECTURE, URBAN PLANNING AND LAND USE PLANNING",
      "F_h3_4":"NEW INFORMATION AND COMMUNICATION TECHNOLOGIES",
      "F_h3_5":"MEDICINE",
      "F_h3_11":"Agronomy",
      "F_h3_12":"Agribusiness and Nutrition",
      "F_h3_13":"Agricultural Engineering",
      "F_h3_14":"Waters and Forests",
      "F_h3_21":"Civil Engineering and Landscape",
      "F_h3_22":"Electrical and Environmental Engineering",
      "F_h3_23":"Topography and Environment",
      "F_h3_24":"Hydraulics and Environment",
      "F_h3_31":"Bioclimatic Architecture",
      "F_h3_32":"Town planning and land use planning",
      "F_h3_41":"Multimedia",
      "F_h3_42":"Communication",
      "F_h3_51":"Public health and environment",
      "F_h3_52":"Medical Biology",
      "Footer_1":"Download startegic plan Horizon 24",
      "Footer_2":"Quick access",
      "Footer_3":"Trainings",      
      // equipe
      "E_1":"Members of the University Council",
      "E_21":"NGUNZA MUHINDO JOHNNY",
      "E_22":"President of the University Council",
      "E_23":"Muhindo is an architect who graduated from the Special School of Architecture in Paris (France). In 2016, this enthusiast of African metropolises founded the asAf university in the city of Beni where he lives; this institution aims to be a center of excellence in the reflection of the African city but also a factor of development of its environment. He is the President of the University Council but also he works in this university as a Teacher-Researcher. Within this institution he shares his various experiences and also pursues his research work which is interested in the study of landscapes and their contributions to the development of peri-urban spaces in the city of Beni.",
      
      "E_31":"LULIHI SAAMBA BERNARD",
      "E_32":"Administration Officer",
      "E_33":"Saamba is in charge of administration within the asAf University and he is a member of the University Council. This former math teacher spent a good part of his professional career at the Compagnie Sucrière de Kiliba (Province of South Kivu). He held the post of Executive Secretary. One of his favorite anecdotes is that of the gentleman who once asked Luther, 'My dear Martin, what would you do, if you were told today that the end of the world is tomorrow?' Luther's response was: 'If I have decided to plant an apple tree, I will plant this apple tree. 'He often concludes this story using his own context:' I too Bernard would like, despite my age, to be among those who will build the African city of tomorrow '.",
      
      "E_52":"Monitoring and Evaluation Officer",
      "E_53":"Judith is in charge of Monitoring and Evaluation within the asAf University and she is a member of the University Council. Holder of a bachelor's degree in Computer Science and Business Management. My motivation for the asAf University: 'my commitment within this institution is that I found there a new system of work, supervision, reflection and in addition a new awareness' hence a world of experts and exploits in its mode of work. '",
      
      "E_61":"KASEREKA MITAVO ALBÉRIC",
      "E_62":"RUN Program Coordinator",
      "E_63":"Albéric is a nurse by training. Within the asAf University he is the Coordinator of the RUN Program. Living in a rural environment, his approach is for a real communication of proximity between the University asAf and its environment. As a result, it is a real umbilical cord between the asAf-U and the populations of the territory of Beni.",
      
      "E_71":"PACIFIQUE MATUMO VINCENT",
      "E_72":"Faculty Coordinator",
      "E_73":"Vincent is the Faculty Coordinator at asAf University. Holder of a bachelor's degree in literature department of English and African cultures at the Higher Pedagogical Institute of Beni (ISP-BENI) in the city of Beni in North Kivu. His motivation for the University asAf: 'My commitment within this Institution, I found seriousness in the work, but also a good vision of things which is to bring a new sound of development in the region through its different faculties. '",
      
      "E_81":"LINDEZA MAMONI ROBERT",
      "E_82":"Director asAfEnt.",
      "E_83":"Robert is a self made man. This graduate in chemistry and biology started doing business very early in his life by offering various services all around him and in this context he met the asAf-U on his journey. Within the asAf-U he holds the position of Director of ASAF Entrepreneurship; he brings his seriousness and his experience to it. His motivation for asAf University: 'I am really touched by the team spirit, the creativity and above all the unity.'",

      "G_1":"Interactive maps of French-speaking and English-speaking countries neighboring Congo",
      "G_2":"AsAf Cell of University Biblical Groups",
      "G_3":"Conferences",
      "G_4":"UasAf students",
      "G_5":"Johnny Ngunza",
      "G_6":"Africa and the Urban",
      "G_7":"the UAsAf",
      "G_8":"Rural University Program",

      // Quoi de neuf
      "W_1":"AFRICA MY CONTINENT",
      "W_11":"Do you know the city of Banjul?",
      "W_12":"is the capital of The Gambia and has a population of 34,828 inhabitants (urban area 523,589) according to the 2003 census.",
      "W_121":"It is located 250km south-east of Dakar, on Sainte-Marie Island (Banjul Island), on the west bank of the mouth of the Gambia River",
      "W_2":"STUDENT CROSSROADS",
      "W_21":"Saturday, May 29, a date not to be missed at the asAf-U. Newcomer Onboarding Day",
      "W_3":"BENI, MY CITY AND MY TERRITORY",
      "W_31":"STOP TO MASSACRES IN THE CITY OF BENI AND ITS SURROUNDINGS !!!!!",
      "W_32":"The UAsAf is in solidarity with the populations of the city of Beni and its surroundings who only reclaim Peace and Justice",
      "W_4":"At asaf-U",
      "W_41":"This Year the University of Other Sound of Africa has started a new faculty:",
      "W_42":"THE FACULTY OF NEW INFORMATION AND COMMUNICATION TECHNOLOGIES",

      // about
      "A_1":"Our background",
      "A_2":"Our environment",
      "A_3":"Our environment is rural",
      "A_4":"Scholarships",
      "A_5":"KASEREKA MAYO FABRICE G3 AGRONOMY (2018-2019)",
      "A_6":"At one point in my life, I took charge early on to pay for my education (primary and secondary) by washing motorcycles and doing odd jobs in electricity. This is how I was able to finance my studies. When I finished high school, I couldn't afford to finance my university studies and the future looked bleak. In 2015, I got a temporary job at UAA in the computer lab. And I thought I was dreaming a year later when the authorities of the institution offered to continue my higher education, because they had selected me so that I could receive a three-year scholarship. In July 2019, I finished my first cycle and I am really re-acquainted with the asAf University and through my training I am ready to make my contribution for the development of my community",
      "A_7":"KAVUGHO NDUNGO CHARMANTE G3 AGRONOMY (2018-2019)",
      "A_8":"I come from a modest family. Very young, I lost my parents. When I finished high school, my tutor made it clear to me that he could no longer assist me in pursuing my higher education. I started doing odd jobs in the hope that I could muster some money to continue my studies, but I couldn't. One day in September 2016, I heard on the radio that the asAf-U was offering scholarships through a competition. I rushed there to get more information. I registered for the contest. My results having been satisfactory, I obtained a scholarship for the three years of my undergraduate. In July 2019, I graduated from graduate school. I am frankly very grateful to asAf University and its authorities. I will invest myself from now on to make my contribution to the development of my city of Beni.",
      "A_9":"Students testify",
      "A_10":"Organizational chart",
      "A_11":"location",
      "A_12":"asAf - another Sound From Africa",
      "A_13":'Democratic Republic of Congo',
      "A_14":"University other sound of Africa",
      "A_15":"AsAf University is also a promoter of intellectual and cultural democracy, allowing the expression of ideas, promoting the emergence of cultural currents stemming from the contemporary concerns of populations. It will direct its actions towards lifelong education, community development and cultural diversity with the aim of constantly seeking to improve the quality of life of all the people who live in the territory where it operates.",
      "A_16":"Taking into account these three aspects that characterize our territory, asAf University grants scholarships to children of farmers so that they can benefit from quality training for their first cycle. Since 2016, we have been selecting young baccalaureate holders who can benefit from this program in order to train young people in the area where we operate so that they can bring their skills to the development of this environment.",
      // Rurale
      "R_1":"In four years of activity, we have learned to better understand our environment: it is rural, poor and endemic to insecurity.",
      "R_2":"We want to intervene as much as possible on these three aspects that characterize our immediate environment because we believe that a university can be a factor in the development of the environment in which it operates.",
      "R_3":"Rural University Program",
      
      // Context
      "C_1":"In addition to the history of the concept of 'another sound of Africa' ​​which dates back to the early 2000s, the first ideas that led to the creation of the University asAf refer to the theme of the African city. All the problems that arise on this (problematic) subject can be summed up in this question:",
      "C_2":"Tomorrow, which city for Africa?",
      "C_3":"Africa is still a rural continent, meaning that today there are still many more rural people than city dwellers. But it is the continent that is rapidly urbanizing. Africans are investing more and more in cities. According to UN-Habitat forecasts, there will be 55% urban in 2030 and more than 60% in 2050; It's soon. How can we prepare for this urban transition today? As a country, city, universities or research centers? How do we Africans prepare for this?",
      "C_4":"In this series of questions on the African city of tomorrow, the promoter of this institution questioned the role that a higher education institution could play in the context of urban transition in Africa. As a result, several ideas, which had to materialize in objectives to be achieved, had emerged. Among these ideas, we can note a series of propositions on the role that a university can play today; among others, a University:",
      "C_5":"- Which is designed to be a center of reflection on the African city.",
      "C_6":"- As a factor in the development of its environment.",
      "C_7":"- Who advocates a different education.",
      "C_8":"- Which operates according to Christian ethics.",
      "C_9":"- Who is a small or a medium-sized structure but effi-cient.",
      "C_10":"-	Qui s’intéresse aux différentes questions de conserva-tion /préservation de la nature et de l’environnement ; aux questions de développement durable et aux enjeux mondiaux sur le changement climatique.",
      "C_11":"-	Qui assure le développement intellectuel et socioculturel de son territoire.",
      "C_12":"- That is innovative.",
      "C_13":"- Which offers tools for creation, expression and communication.",
      "C_14":"- Which advocates a frank and constructive dialogue between cultures.",
      "C_15":"- Who will direct their actions towards lifelong education and community development.",
      "C_16":"The goal is to constantly seek to improve the quality of life of all the people who live in the area where it operates.",
      "C_17":"On this, the vision defined for this project was stated in this direction: <b> Reinventing the African city </b>. The asAf University has the ambition to reinvent * the African city in general and the Congolese city in particular through training ** and thus make its contribution to find adequate responses to the needs of the populations of this continent (Africa ) which is going through its urban transition.",
      "C_18":"* 'Reinventing the urban transition'. This is the message of UN Habitat which published in 2014 a very in-depth study on the urbanization of the continent: 'The state of African cities 2014'. This study shows that African countries will have to respond quickly to the coming explosion in the number of city dwellers. UN Habitat believes that the models of urbanization inherited from the past are no longer valid in the face of population growth in a context of widespread poverty and climate change.",
      "C_19":"** By focusing more on learning and the acquisition of skills, without neglecting the other classic pillars of a university, which are research and publication. In our context, we will add a fourth pillar: the project.",
      
      // Milieu
      "M_1":"Why the city of Beni? Why the territory of Beni? Why the city of Butembo?",
      "M_2":"The following factors contributed to these choices:",
      "M_3":"- The proximity between these three administrative entities.",
      "M_4":"- The presence in these three zones of infrastructures belonging to the promoter and able to accommodate this project. These infrastructures are made available to the project by the promoter in agreement with the members of his family (see page 26).",
      "M_5":"- In this context of urban transition, there is a very interesting case study between a rural environment (the territory of Beni) and two urban environments (the cities of Beni and Butembo). In Africa, the largest cities are not absorbing the bulk of current population growth, and this is not expected to change; cities of at least one million inhabitants typically absorb, on average, only about 25 percent of a country's urban population growth, with the 'remaining' 75 percent being distributed among the cities in the country. - intermediaries and others; and therefore, it is in the latter category that the needs are greatest in terms of urban management, capacity building and service delivery (UN-Habitat, 2014).",
      "M_6":"- In addition to the urban transition, peri-urbanity is one of the main characteristics of the cities of Beni and Butembo. Nowadays, observing most cities in Africa - particularly in sub-Saharan Africa - one fact draws attention: the notions of city and countryside tend to merge in the new ex-tensions of these agglomerations. Of course, on administrative maps the boundaries between urban and rural areas are clear and precise; but in the field this delimitation is not always easy to grasp. Is it the countryside entering the city? Is it the urban world which engulfs the rural world? Is it a buffer zone between the city and the rural areas? Is this the birth of a new form of urbanization? Any informed observer cannot ignore this phenomenon. These are 'fringe' areas because they extend both to the outskirts of the city and to the limit of rural space; these worlds overlap and intertwine, making it difficult to establish where the urban ends and the rural begins (BOGAERT and HALLEUX, 2015). Urban transition in developing countries is reflected in the emergence of new territorial and social realities where towns and countryside intermingle in hybrid and unprecedented forms (pacodel@ulg.ac.be - www.ulg. ac.be/ri-pacodel, consulted on 03/05/2017). Peri-urban spaces are not inevitable but a reality in African cities, and this reality must be supported.",
      "M_7":"- Finally, the city of Beni being the place of residence of the promoter of the University, the latter was keen to contribute to the development of his city and the surrounding areas. Population City of Beni: 771,476 inhabitants ( Source: annual report of the administration of the city of Beni, year 2018). Beni territory: 1,400,138 inhabitants (Source: sy-noptic table of the Congolese and foreign population in Beni territory, year 2019).",
      "M_8":"City of Butembo",
      
      "M_81":": 950,488 inhabitants (Source: report of the administration of the city of Butembo, first quarter of 2020) Population under 20 City of Beni: 64% (Source: annual report of the administration of the city of Beni, year 2018 ).",
      "M_9":"Beni Territory",
      "M_91":": 54% (Source: synoptic table of the Congolese and foreign population in Beni territory, year 2019).",
      "M_10":"City of Butembo",
      "M_101":": 65% (Source: Civil Registry Office / Butembo Town Hall, quarterly report October-November-December 2020) Poverty line",
      "M_11":"City of Beni",
      "M_111":": 92.4% (Source: UNDP / 2019 annual report).",
      "M_12":"Beni Territory",
      "M_121":": 97.8% (Source: UNDP / 2019 annual report).",
      "M_13":"City of Butembo",
      "M_131":": 90% (Source: Social Affairs Office, 2019 annual report) Farmers population",
      "M_14":"City of Beni",
      "M_141":": 74,89 %",
      "M_15":"Beni Territory",
      "M_151":": 94.8% (Source: Inspection of agriculture, fishing and breeding agripel-beni@gmail.com, annual report 2019).",
      "M_16":"City of Butembo",
      "M_161":": 87% (Source: Bureau AGRI / Butembo, interview 2020).",
      "M_17":"City of Beni",
      "M_171":" : 280,896 km²",
      "M_18":"Beni Territory",
      "M_181":"7 484 km²",
      "M_19":"City of Butembo",
      "M_191":"190, 34 km²",
      "M_20":"The proximity to the Virunga National Park (consecrated world heritage in 1979 for its exceptional biodiversity) which can be a great asset for the development of the region.",
      "M_21":"Geographically in relation to the city of Beni, the Virunga National Park is located in the east of the DRC, in the province of North Kivu, bordering the DRC-Uganda border on the one hand, and the Rwanda on the other hand. It covers an area of ​​785,000 hectares and is stretched over a distance of nearly 300 km: an average width rarely exceeding 50 km (LANGUY and DE MERODE, 2006). It is located at 10 35 ’south latitude and between 290 01’ and 300 01 ’east longitude (DELVINGT et al, 1990). The territories of Beni and Lubero are located in the far north of the province of North Kivu, often referred to as Grand-Nord to show their northern position in the province of North Kivu.",
      "M_22":"These two territories cover 25,580 km2 or 42.9% of the area of ​​the province, including 18,096 km2 for the territory of Lubero and 7,484 km2 for the territory of Beni (Source: Ar-chives Mairie de Butembo, 2000).",
      "M_23":"Endemic insecurity",
      "M_24":"For example, from January 01 to June 30, 2020: Number of deaths: 1,066 including 358 in the territory of Beni and 708 in the territory of Djugu (in the neighboring province of Ituri). Number of attacks: 117 including 51 in Beni territory and 66 in Djugu territory. In addition, for the two territories 166 wounded, 717 people kidnapped, 59 children recruited into armed groups. Looting and burning of 17 houses, 1 school and 7 health centers. Sources: Semi-annual report of the Research Center for Human Rights (CRDH) and OCHA (United Nations)",
      "M_25":"Distances",
      "M_26":"Beni-Kisangani (North-West) RN4: 700 km; access to the Congo River.",
      "M_27":"Beni-Mavivi Airport (North) RN4: 13 km; a track 2000 meters long and 20 meters wide.",
      "M_29":"Beni-Butembo (South) RN2: 57.5 km; like Beni, the city of Butembo is one of the nine socio-economic cities of the DRC.",
      "M_30":"Beni-Goma (South) RN2: 357.7 km; Chief town of the Province Other important details which require more in-depth investigations in order to bring out exact figures in order to find suitable solutions to these various problems:",
      "M_28":"Beni-Kasindi (East) RN4: 80 km; Uganda border.",
      "M_31":"- The literacy rate of these three entities.",
      "M_32":"- There is a certain economic dynamic among some local traders. It remains to measure the real impact that this activity has on the development of this region.",
      "M_h1":"Beni, Butembo and Territory of Beni",
      
      // Val
      "Val_1":"Feeling of benevolence and kindness towards others.",
      "Val_11":"We believe that education can help save lives and improve living conditions. For us, every human being has a value; This is why we are defending the Universal Declaration of Human Rights adopted by the United Nations on December 10, 1948.",
      "Val_2":"To comply with what duty and moral conscience dictate. Proverbs (Book of the Bible) 14:34 'Righteousness lifts up a nation ...'",
      "Val_21":"When talking about integrity, we can also take into account morality, honesty, courtesy, respect. By integrating all of these principles, we can begin to build a just society, one that conforms to the principles of justice, and that is driven by the principles of law and equity.",
      "Val_3":"Training - Instruction",
      "Val_31":"Nous prônons une éducation de base de qualité pour tous les enfants, jeunes et adultes. Nous pensons que c’est l’un des meilleurs moyens pour élever un homme dans sa société et pour lui donner de la dignité.",
      "Val_4":"That lasts a long time and remains stable. Genesis (book of the Bible) 2:15 'The Lord God took the man and put him in the Garden of Eden to work it and to keep it.'",
      "Val_41":"Meet the needs of the present without compromising the ability of future generations to meet their own needs; and take measures to limit or eliminate the negative impact of human activities on his environment.",
      "Val_5":"Promotion of women",
      "Val_51":"Reduce the vulnerability of women by empowering them to participate fully in economic life in all sectors of life.",
    }
  },
  fr: {
    translation: {
      "Home": "Accueil",
      "History":'Historique',
      "Faculty":"Faculté",
      "Team":'Notre équipe',
      "Gallery":"Gallerie",
      "Heading":"Université autre son d'Afrique",
      "More":"En savoir plus",
      "H1":"PROPOSER D'AUTRES SONS AU SERVICE DE LA PAIX ET DU DÉVELOPPEMENT DE LA RÉGION DES GRANDS LACS EN AFRIQUE, LOIN DE LÀ L'IDÉE D'ÊTRE LES MEILLEURS, MAIS TOUT SIMPLEMENT ÊTRE UN AUTRE SON DE CLOCHE QUI APPORTERA UN PLUS AU DÉVELOPPEMENT DU CONTINENT AFRICAIN",
      "Motto_title":"NOTRE DEVISE",
      "Motto":"éduquer autrement",
      "Motto_1":"L'université autre son d'Afrique, en sigle UasAf, est un établissement privé d'enseignement supérieur. Cette institution est reconnue officiellement par le Ministère de l'Enseignement Supérieur et Universitaire au travers de l'arrêté N 058 du 14 Février 2019.",
      "Vision":"Être un pôle d’excellence en ce qui concerne la réflexion et la conception de la ville africaine.",
      "Mission":"Former des femmes et des hommes qui vont concevoir la ville africaine.",
      "Values_Title":"Nos valeurs",
      "Integrity":"Integrité",
      "Humanity":"Humanité",
      "Durability":"DURABLE(DÉVELOPPEMENT DURABLE ET PROTECTION DE L’ENVIRONNEMENT)",
      "Education":"Education Pour Tous",
      "Empowerment":"AUTONOMISATION DE LA FEMME",
      "What":"Quoi de neuf?",
      "W_Title1":"AFRIQUE MON CONTINENT",
      // historique
      "H_h1":"La force du rêve",
      "H_h2":"L’an 2000 et la naissance d’un concept…",
      "H_h3":"En 1998, j’ai posé mes valises à Rabat au Maroc pour y poursuivre mes études. C’est dans ce pays que j’ai connu le Groupe Biblique Universitaire (GBU), un mouvement composé d’étudiants internationaux et qui fait partie de la grande famille de l’IFES*. J’ai intégré ce mouvement cette année là et j’en fais partie jusqu’à ce jour.",
      "H_h3_1":"De ce mouvement, j’ai tiré une pensée importante qui m’a accompagnée tout au long de mon cursus académique : En tant que chrétien, je suis appelé à contribuer de façon significative au développement de mon pays. C’est en l’an 2000, après une rencontre de musiciens à la-quelle j’avais pris part que le concept 'autre son d’Afrique' a jailli en moi. C’était un soir d’été à Casablanca, je me souviens comme si c’était hier, après cette réunion je me suis posé ces trois questions tout au long du dîner :",
      "H_h3_2":"Pourquoi les sons que j’entends de l'Afrique, particuliè-rement de ma région des Grands Lacs, sont-ils souvent ceux des armes ?",
      "H_h3_3":"En tant qu’étudiant, artiste et futur architecte, quels autres sons puis-je proposer pour que nous ne reflétions plus ces images horribles ?",
      "H_h3_4":"Que peuvent apporter la culture et l'éducation au déve-loppement du continent africain et à sa pacification ?",
      "H_h4":"J’ai eu un rêve ce jour-là...",
      "H_h5":"Celui de promouvoir l'éducation et la culture dans la région des Grands Lacs en Afrique, plus précisément à l'Est de la Répu-blique Démocratique du Congo. Proposer d'autres sons au ser-vice de la paix et du développement de la région des Grands Lacs.",
      "H_h5_1":"Le concept 'autre son d’Afrique' venait de naître, loin de là l'idée d'être les meilleurs, mais tout simplement un autre son de cloche qui apportera un plus au développement du continent africain.",
      "H_h5_2":"Promoteur de l’Université asAf",
      "H_h6":"Le rêve devient réalité",
      "H_h7":"16 ANS APRES L’APPEL DE CASABLANCA…",
      "H_h8_1":"Seize ans après l'appel de Casablanca, l'Université de l'Autre Son d'Afrique, en abrégé « UasAf », a été fondée dans la ville de Beni, à l'est de la République démocratique du Congo.",
      "H_h8_2":"L'UAsAf est un établissement d'enseignement supérieur privé.",
      "H_h8_3":"Cet établissement est officiellement reconnu par le Ministère de l'Enseignement Supérieur et Universitaire à travers l'arrêté n°058 du 14 février 2019.",
      "H_h9":"FAIRE UN DON",
      "H_h10":"Envoyer",
      "H_h11":"En completant ce formulaire, vous permetrez à l'administration de vous contacter pour tout don que vous aimeriez faire pour Université asAf",
      // Facultes
      "F_h1":"L'homme dans la ville",
      "F_h1_1":"NOTRE MODÈLE DE FORMATION",
      "F_h2_1":"I. Nos quatre domaines d’intervention dans la ville africaine",
      "F_h2_11":"Alimentation, Santé, Habitat & Infrastructures et NTIC",
      "F_h2_2":"II. Formation Bilingue (Français-anglais)",
      "F_h2_22":"Une partie de la formation se fera en anglais (en projet).",
      "F_h2_3":"III. Notre “ Paquet Formation”",
      "F_h2_3_1":"Programme officiel",
      "F_h2_3_11":"Transmission des connaissances",
      "F_h2_3_2":"Ateliers d'innovations sur la ville africaine",
      "F_h2_3_2_1":"Conception et Acquisition des compétences",
      "F_h2_3_2_2":"Formations et Apprentissage",
      "F_h2_3_2_3":"Écriture des projets et Innovations",
      "F_h2_3_3":"Recherche et Publication sur la ville en Afrique",
      "F_h2_3_3_1":"Conception et Acquisition des compétences",
      "F_h2_3_3_2":"Formations et Apprentissage",
      "F_h2_3_3_3":"Écriture des projets et Innovations",
      "F_h2_3_4":"Les Réseaux asAf pour l’entrepreneuriat dans la ville en Afrique",
      "F_h2_3_4_1":"Conception et Acquisition des compétences",
      "F_h2_3_4_2":"Formations et Apprentissage",
      "F_h2_3_4_3":"Écriture des projets et Innovations",
      "F_h3":"NOS FACULTÉS & DÉPARTEMENTS",
      "F_h3_1":"SCIENCES AGRONOMIQUES ET GENIES RURALES",
      "F_h3_2":"SCIENCES ET DES TECHNOLOGIES APPLIQUÉES À L’ENVIRONNEMENT",
      "F_h3_3":"ARCHITECTURE BIOCLIMATIQUE, URBANISME ET AMÉNAGEMENT DU TERRITOIRE",
      "F_h3_4":"NOUVELLES TECHNOLOGIES DE L’INFORMATION ET DE LA COMMUNI-CATION",
      "F_h3_5":"MEDECINE",
      "F_h3_11":"Agronomie",
      "F_h3_12":"Agro-alimentaire et Nutrition",
      "F_h3_13":"Génie Rural",
      "F_h3_14":"Eaux et Forets",
      "F_h3_21":"Génie civil et Paysage",
      "F_h3_22":"Génie Électrique et Environnement",
      "F_h3_23":"Topographie et Environnement",
      "F_h3_24":"Hydraulique et Environnement",
      "F_h3_31":"Architecture Bioclimatique",
      "F_h3_32":"Urbanisme et aménagement du territoire",
      "F_h3_41":"Multimédia",
      "F_h3_42":"Communication",
      "F_h3_51":"Santé publique et environnement",
      "F_h3_52":"Biologie Médicale",
      "Footer_1":"Télécharger Plan stratégique Horizon 24",
      "Footer_2":"Accès rapide",
      "Footer_3":"Formations",
      
      // equipe
      "E_1":"Les membres du Conseil de l'Université",
      "E_21":"NGUNZA MUHINDO JOHNNY",
      "E_22":"Président du Conseil de l’Université",
      "E_23":"Muhindo est architecte diplômé de l’École Spéciale d’Architecture à Paris (France). En 2016 ce passionné des métropoles africaines fonde l’université asAf dans la ville Beni où il réside ; cette institution se veut être un pôle d’excellence sur la réflexion de la ville africaine mais aussi un facteur de développement de son milieu. Il est le Président du Conseil de l'Université mais aussi il travaille dans cette université en tant que Enseignant-Chercheur. Au sein de cette institution il partage ses diverses expériences et il y poursuit aussi ses travaux de recherche qui s’intéressent à l’étude des paysages et à leurs apports dans l’aménagement des espaces périurbains de la ville de Beni.",
      "E_31":"LULIHI SAAMBA BERNARD",
      "E_32":"Chargé de l’Administration",
      "E_33":"Saamba est en charge de l'administration au sein de l'Université asAf et il est membre du Conseil de l'Université. Cet ancien professeur de mathématiques a passé une bonne partie de sa carrirère professionnelle à la Compagnie Sucrière de Kiliba (Province du Sud-Kivu). Il y occupait le poste de Secrétaire de Direction. L’une de ses anecdotes favorites est celle du Monsieur qui demanda un jour à Luther : « Mon cher Martin, que feriez-vous, si on vous apprenait aujourd’hui, que la fin du monde est pour demain? » Voici la réponse de Luther : « Si j’ai décidé de planter un pommier, je planterai ce pommier. » Il conclut souvent cette histoire en utilisant son propre contexte : « Moi aussi Bernard je voudrais, malgré mon âge être de ceux qui bâtiront la ville africaine de demain ».",
      "E_41":"KASEREKA KAHUKO RICHARD",
      "E_42":"Chargé du Budget",
      "E_43":"Richard est le Chargé du Budget au sein de l’Université asAf et il est membre du Conseil de l’Université. Détenteur d’un diplôme de graduat en Science Commerciale et Financière, option Comptabilité à l’Institut Supérieur de Commerce/ ISC (Nord-Kivu/ Beni). Sa motivation pour l’Université asAf : « Si j’ai décidé de m’engager au sein de cette institution, c’est parce que j’y ai ressenti une vision promettante et un nouveau mode de travail que je n’ai pas trouvé ailleurs. Bref c’est une Université qui a un avenir meilleur. »",
      "E_51":"MWITO KAREKEZI JUDITH",
      "E_52":"Chargée du Suivi et Évaluation",
      "E_53":"Judith est en charge du Suivi et Evaluation au sein de l'Université asAf et elle est membre du Conseil de l'Université. Titulaire d'un diplôme de licence en Informatique et Gestion des affaires. Ma motivation pour l'Université asAf : « mon engagement au sein de cette institution, est que j'y ai trouvé un nouveau système du travail, d'encadrement, de réflexion et en plus une prise en conscience de nouveau' d'où un monde des experts et des exploits dans son mode du travail. »",
      "E_61":"KASEREKA MITAVO ALBÉRIC",
      "E_62":"Coordonnateur du programme RUN",
      "E_63":"Albéric est infirmier de formation. Au sein de de l’Université asAf il est le Coordonnateur du Programme RUN. Vivant en milieu rural, son approche est pour une véritable communication de proximité entre l'Université asAf et son milieu. De ce fait, il est un véritable cordon ombilical entre l’UasAf et les populations du territoire de Beni.",
      "E_71":"PACIFIQUE MATUMO VINCENT",
      "E_72":"Coordonnateur Facultaire",
      "E_73":"Vincent est le Coordonnateur Facultaire au sein de l’Université asAf. Titulaire d’une licence en lettres département d’Anglais et cultures Africaines à l’Institut Supérieur Pédagogique de Beni (ISP-BENI) dans la ville de Beni au Nord-Kivu. Sa motivation pour l’Université asAf : « Mon engagement au sein de cette Institution, j’ai trouvé du sérieux dans le travail, mais aussi une bonne vision des choses qui est d’amener un nouveau son du développement dans la région à travers ses différentes facultés.»",
      "E_81":"LINDEZA MAMONI ROBERT",
      "E_82":"Directeur asAfEnt.",
      "E_83":"Robert est un self made man. Ce diplômé en chimie biologie a commencé à entreprendre très tôt dans sa vie en proposant divers services tout autour de lui et dans ce contexte qu’il a croisé l’UasAf sur son parcours. Au sein de l’UasAf il occupe les fonctions de Directeur d’asAf Entrepreneuriat ; il y apporte son sérieux et son expérience. Sa motivation pour l’Université asAf : « je suis vraiment touché par l'esprit d'équipe, la créativité et surtout l’unité.»",
      
      "G_1":"Cartes interactives pays francophones et anglophones voisins du Congo",
      "G_2":"Cellule asAf des Groupes Bibliques Universitaires",
      "G_3":"Conférences",
      "G_4":"Etudiants à l'UasAf",
      "G_5":"Johnny Ngunza",
      "G_6":"l'Afrique et l'Urbain",
      "G_7":"l'UasAf",
      "G_8":"Programme Université Rurale",
      
      // Quoi de neuf
      "W_1":"AFRIQUE MON CONTINENT",
      "W_11":"Connaissez vous la ville de Banjul?",
      "W_12":"Banjul est la capitale de la Gambie et a une population de 34 828 habitants(aire urbaine 523589) selon le recensement de 2003.",
      "W_121":"Elle est située à 250km au sud-est de Dakar, sur l'ile Sainte-Marie(ile Banjul), sur la rive ouest de l'embouchure du fleuve Gambie",
      "W_2":"CARREFOUR DES ETUDIANTS",
      "W_21":"Le samedi 29 Mai une date à ne pas manquer à l'UasAf. Journée de L'intégration des nouveaux",
      "W_3":"BENI, MA VILLE ET MON TERRITOIRE",
      "W_31":"STOP AUX MASSACRES DANS LA VILLE DE BENI ET SES ENVIRONS!!!!!",
      "W_32":"L'UasAf est solidaire avec les populations de la ville de Beni et ses environs qui ne reclamment que Paix et Justice",
      "W_4":"A L'UASAF",
      "W_41":"Cette Année l'université autre son d'Afrique a démarré une nouvelle faculté: ",
      "W_42":"LA FACULTE DES NOUVELLES TECHNOLOGIES DE L'INFORMATION ET DE LA COMMUNICATION",
      
      // about
      "A_1":"Notre Contexte",
      "A_2":"Notre Milieu",
      "A_3":"Notre Milieu est rurale",
      "A_4":"Bourses d'études",
      "A_5":"KASEREKA MAYO FABRICE G3 AGRONOMIE (2018-2019)",
      "A_6":"A un certain moment de ma vie, je me suis pris en charge très tôt pour payer mes études (primaires et secondaires) en lavant des motos et en faisant de petits boulots d’électricité. C’est ainsi que j’ai pu financer mes études. Au terme de mes études secondaires, je n’avais plus les moyens pour financer mes études universitaires et l’avenir me paraissait bien sombre. En 2015, j’ai eu un emploi temporaire à l’UasAf dans le laboratoire in-formatique. Et je croyais rêver une année plus tard quand les autorités de l’institution m’ont proposé de continuer mes études supérieures, car elles m’avaient sélectionné pour que je puisse bénéficier d’une bourse d’études de trois ans. En Juillet 2019, j'ai fini mon premier cycle et je suis vraiment re-connaissant envers l’Université asAf et de part ma formation je suis prêt à apporter ma contribution pour le développement de ma communauté",
      "A_7":"KAVUGHO NDUNGO CHARMANTE G3 AGRONOMIE (2018-2019)",
      "A_8":"Je suis issue d’une famille modeste. Très jeune, j’ai perdu mes parents. Quand j’ai terminé mes études secondaires, mon tuteur m’a clairement signi-fié qu’il ne pouvait plus m’assister pour la poursuite de mes études supé-rieures. J’ai commencé à faire de petits boulots dans  l’espoir de pouvoir réunir un peu de moyens afin de continuer mes études, mais je n’y parvenais pas. Un jour de septembre 2016, j’ai suivi à la radio que l’UasAf offrait des bourses d’études sur présentation d’un concours. Je m’y suis précipitée pour avoir des renseignements supplémentaires.  Je me suis faite inscrire pour le concours. Mes résultats ayant été satisfai-sants, j’ai obtenu une bourse d’études pour les trois années de mon premier cycle. En Juillet 2019, j’ai obtenu mon diplôme de graduat. Je suis franche-ment très reconnaissante envers l’Université asAf et ses autorités. Je vais m’investir dorénavant pour apporter ma contribution pour le développement de ma ville de Beni.",
      "A_9":"Les Etudiants témoignent",
      "A_10":"Organigramme",
      "A_11":"localisation",
      "A_12":"asAf - autre son d'Afrique",
      "A_13":'République Démocratique du Congo',
      "A_14":"Université autre son d'Afrique",
      "A_15":"L’UNIVERSITÉ ASAF EST AUSSI UN PROMOTEUR DE DÉMOCRATIE INTELLECTUELLE ET CULTURELLE, PERMETTANT L’EXPRESSION DES IDÉES, FAVORISANT L’ÉMERGENCE DE COURANTS CULTURELS ISSUS DES PRÉOCCUPATIONS CONTEMPORAINES DES POPULATIONS. ELLE VA ORIENTER SES ACTIONS VERS L’ÉDUCATION PERMANENTE, LE DÉVELOPPEMENT COMMUNAUTAIRE, LA DIVERSITÉ CULTURELLE DANS LE BUT DE RECHERCHER EN PERMANENCE L’AMÉLIORATION DE LA QUALITÉ DE VIE DE L’ENSEMBLE DES PERSONNES QUI PEUPLENT LE TERRITOIRE OÙ ELLE AGIT.",
      "A_16":"En tenant compte de ce trois aspects qui caractérisent notre territoire, l’Université asAf accorde des bourses d’études aux enfants d’agriculteurs afin qu’ils puissent bénéficier d’une for-mation de qualité pour leur premier cycle. Depuis 2016, nous sélectionnons des jeunes bacheliers qui peuvent bénéficier de ce programme dans le but de former des jeunes dans le territoire où nous évoluons afin que ces derniers puissent apporter leurs com-pétences dans le développement de ce milieu.",
      // Rurale
      "R_1":"En quatre années d’activités, nous avons appris à mieux comprendre notre milieu : il est rural, pauvre et en proie à une insécurité endémique.",
      "R_2":"Nous voulons intervenir dans la mesure du possible sur ces trois aspects qui caractérisent notre environnement immédiat parce que nous croyons qu’une université peut être un facteur de développement du milieu dans lequel elle agit.",
      "R_3":"Programe Unniversité rurale",
      
      // Context
      "C_1":"En plus de l’historique du concept « autre son d’Afrique » qui remonte au début des années 2000, les premières idées qui ont poussé à la création de l’Université asAf font références à la thématique de la ville africaine. L’ensemble des problèmes qui se posent sur ce sujet (problématique) peut se résumer en cette question : ",
      "C_2":"Demain, quelle ville pour l’Afrique ?",
      "C_3":"L’Afrique est encore un continent rural, c’est-à-dire qu’on y trouve encore à l’heure actuelle beaucoup plus de ruraux que de citadins. Mais c’est le continent qui s’urbanise rapidement. Les africains investissent de plus en plus les villes. Selon les prévi-sions de l’UN-Habitat, il y aura 55% d’urbains en 2030 et plus de 60% en 2050 ; c’est bientôt. Aujourd’hui, comment préparer cette transition urbaine ? En tant que pays, ville, universités ou centres de recherches ? Comment nous africains on s’y prépare ?",
      "C_4":"Dans cette suite de questionnements sur la ville africaine de demain, le promoteur de cette institution s’était interrogé sur le rôle que pouvait jouer une institution d’enseignement supérieur dans le contexte de transition urbaine en Afrique. Partant, plu-sieurs idées, qui devaient se concrétiser en objectifs à atteindre, avaient émergé. Parmi ces idées, nous pouvons noter une série de propositions sur le rôle que peut jouer une université de nos jours ; entre autres, une Université :",
      "C_5":"-	Qui est conçue pour être un pôle de réflexion sur la ville africaine.",
      "C_6":"-	Comme facteur de développement de son milieu.",
      "C_7":"-	Qui prône une éducation différente.",
      "C_8":"-	Qui fonctionne selon l’éthique chrétienne.",
      "C_9":"-	Qui soit une petite ou une moyenne structure mais effi-ciente.",
      "C_10":"-	Qui s’intéresse aux différentes questions de conserva-tion /préservation de la nature et de l’environnement ; aux questions de développement durable et aux enjeux mondiaux sur le changement climatique.",
      "C_11":"-	Qui assure le développement intellectuel et socioculturel de son territoire.",
      "C_12":"-	Qui soit innovante.",
      "C_13":"-	Qui propose des outils de création, d’expression et de communication. ",
      "C_14":"-	Qui prône un dialogue franc et constructif entre les cul-tures.",
      "C_15":"-	Qui va orienter ses actions vers l'éducation permanente et le développement communautaire.",
      "C_16":"Le but étant de rechercher en permanence l’amélioration de la qualité de vie de l’ensemble des personnes qui peuplent le ter-ritoire où elle agit.",
      "C_17":"Sur ce, la vision définie pour ce projet a été énoncée dans ce sens : <b>Réinventer la ville africaine</b>. L’Université asAf a l’ambition de réinventer* la ville africaine en général et la ville congolaise en particulier par la formation** et d’apporter ainsi sa contribution pour trouver des réponses adéquates aux besoins des populations de ce continent (l’Afrique) qui est en train de vivre sa transition urbaine.",
      "C_18":"* « Réinventer la transition urbaine ». C’est le message de l’ONU Habitat qui a publie en 2014 une étude très fouillée sur l’urbanisation du continent : « L’état des villes africaines 2014 ». Cette étude montre que les pays africains vont devoir apporter rapidement des réponses face à l’explosion à venir du nombre de citadins.  L’ONU Habitat estime que les modèles d’urbanisation hérités du passé ne sont plus valables face à l’accroissement de la population dans un contexte de pauvreté générali-sée et au changement climatique.",
      "C_19":"**En misant plus sur l'apprentissage et l'acquisition des compétences, sans pour au autant négliger les autres piliers classiques d'une université qui sont la recherche et la publication. Dans notre contexte, nous ajouterons un quatrième pilier : le projet. ",
      
      // Milieu
      "M_1":"Pourquoi la ville de Beni ? Pourquoi le territoire de Beni ? Pourquoi la ville de Butembo ?",
      "M_2":"Les facteurs suivants ont concouru à ces choix :",
      "M_3":"- La proximité entre ces trois entités administratives.",
      "M_4":"- La présence dans ces trois zones des infrastructures ap-partenant au promoteur et pouvant accueillir ce projet. Ces infrastructures sont mises à la disponibilité du projet par le promoteur en accord avec les membres de sa fa-mille (cfr. page 26).",
      "M_5":"- Dans ce contexte de transition urbaine, il y a là un cas d’étude très intéressant entre un milieu rural (le territoire de Beni) et deux milieux urbains (les villes de Beni et de Butembo). En Afrique, ce ne sont pas les plus grandes villes qui absorbent le plus gros de la croissance actuelle de la population, et il n’est pas prévu que cela change ; les villes d’au moins un million d’habitants n’absorbent généralement, en moyenne, que quelque 25 pour cent de l’accroissement de la population urbaine d’un pays, les 75 pour cent « restants » se répartissant entre les villes in-termédiaires et autres ; et par conséquent, c’est dans cette dernière catégorie que les besoins se font le plus sentir en matière de gestion urbaine, de renforcement des capaci-tés et de mise en place de services (ONU-Habitat, 2014).",
      "M_6":"- En outre de la transition urbaine, la périurbanité est l’une des principales caractéristiques des villes de Beni et de Butembo. De nos jours, en observant la plupart de villes en Afrique – particulièrement en Afrique subsaharienne – un fait attire l’attention : les notions de ville et de cam-pagne ont tendance à se confondre dans les nouvelles ex-tensions de ces agglomérations. Certes, sur les cartes ad-ministratives les délimitations entre les zones urbaines et les zones rurales sont nettes et précises ; mais sur le ter-rain cette délimitation n’est pas toujours aisée à saisir. Est-ce la campagne qui pénètre dans la ville ? Est-ce le monde urbain qui engloutit le monde rural ? Est-ce une zone tampon entre la ville et les zones rurales ? Est-ce la naissance d’une nouvelle forme d’urbanisation ? Tout ob-servateur averti ne peut ignorer ce phénomène. Ce sont des zones de « frange » parce qu’elles s’étendent aussi bien aux abords de la ville qu’à la limite de l’espace ru-ral ; ces mondes se chevauchent et s’entremêlent, rendant difficile d’établir où l’urbain se termine et où le rural commence (BOGAERT et HALLEUX, 2015). La transi-tion urbaine dans les pays en développement se traduit par l’émergence de nouvelles réalités territoriales et so-ciales où villes et campagnes s’entremêlent dans des formes hybrides et inédites (pacodel@ulg.ac.be - www.ulg.ac.be/ri-pacodel, consulté le 05/03/2017). Les espaces périurbains ne sont pas une fatalité mais une réa-lité dans les villes africaines, et cette réalité doit d’être accompagnée.",
      "M_7":"- Enfin la ville de Beni étant le lieu de résidence du pro-moteur de l’Université, ce dernier avait à cœur de contri-buer au développement de sa ville et des territoires qui l’environnent.PopulationVille de Beni : 771 476 habitants (Source : rapport annuel de l’administration de la ville de Beni, année 2018). Territoire de Beni : 1 400 138 habitants (Source : tableau sy-noptique de la population congolaise et étrangère en territoire de Beni, année 2019).",
      "M_8":"Ville de Butembo",
      
      "M_81":" : 950 488 habitants (Source : rapport de l’administration de la ville de Butembo, premier trimestre 2020).Population moins de 20 ansVille de Beni : 64 % (Source : rapport annuel de l’administration de la ville de Beni, année 2018).",
      "M_9":"Territoire de Beni ",
      "M_91":": 54 % (Source : tableau synoptique de la population congolaise et étrangère en territoire de Beni, année 2019).",
      "M_10":"Ville de Butembo",
      "M_101":": 65 % (Source : Bureau état-civil/Mairie de Butembo, rapport trimestriel Octobre-Novembre-Décembre 2020).Seuil de pauvreté",
      "M_11":"Ville de Beni ",
      "M_111":": 92,4 % (Source : rapport annuel PNUD/2019).",
      "M_12":"Territoire de Beni",
      "M_121":": 97,8 % (Source : rapport annuel PNUD/2019).",
      "M_13":"Ville de Butembo",
      "M_131":" : 90 % (Source : Bureau affaires sociales, rap-port annuel 2019).Population agriculteurs",
      "M_14":"Ville de Beni ",
      "M_141":": 74,89 %",
      "M_15":"Territoire de Beni",
      "M_151":": 94,8 % (Source : Inspection de l’agriculture, pêche et élevage agripel-beni@gmail.com, rapport annuel 2019).",
      "M_16":"Ville de Butembo ",
      "M_161":": 87 % (Source : Bureau AGRI/Butembo, interview 2020).Superficie",
      "M_17":"Ville de Beni ",
      "M_171":" : 280,896 km²",
      "M_18":"Territoire de Beni :",
      "M_181":" 7 484 km²",
      "M_19":"Ville de Butembo : ",
      "M_191":"190, 34 km²",
      "M_20":"La proximité avec le parc national des Virunga (consacré patri-moine mondial en 1979 pour son exceptionnelle biodiversité) qui peut être un grand atout pour le développement de la contrée.",
      "M_21":"Sur le plan géographique en rapport avec la ville de Beni, le parc national des Virunga se situe à l’Est de la RDC, dans la province du Nord-Kivu, en bordure de la frontière RDC-Ouganda d’une part, et le Rwanda d’autre part. Il couvre une superficie de 785 000 hectares et est allongé sur une distance de près de 300 Km : Une largeur moyenne dépassant rarement 50 Km (LANGUY et DE MERODE, 2006). Il est situé à 10 35’ de latitude sud et entre 290 01’ et 300 01’ de longitude Est (DELVINGT et al, 1990). Les territoires de Beni et de Lubero sont situés à l’extrême Nord de la province du Nord-Kivu, dési-gnés souvent par Grand-Nord pour montrer leur position Nord dans la province du Nord-Kivu.",
      "M_22":"Ces deux territoires couvrent 25 580 Km2 soit 42,9 % de la superficie de la province dont 18 096 Km2 pour le territoire de Lubero et 7 484 Km2 pour le territoire de Beni (Source : Ar-chives Mairie de Butembo, 2000).",
      "M_23":"Une insécurité endémique",
      "M_24":"Pour exemple, du 01 janvier au 30 Juin 2020 : Nombre de morts : 1 066 dont 358 dans le territoire de Beni et 708 dans le territoire de Djugu (dans la province voisine de l’Ituri). Nombre d’attaques : 117 dont 51 dans le territoire de Beni et 66 dans le territoire de Djugu. En outre pour les deux territoires 166 blessés, 717 personnes enlevées, 59 enfants recrutés dans les groupes armées. Pillage et incendie de 17 maisons, 1 école et 7 centres de santé. Sources : Rapport semestriel du Centre de Recherche pour le Droit de l’Homme (CRDH) et OCHA (Nations Unies)",
      "M_25":"Distances",
      "M_26":"Beni-Kisangani (Nord-Ouest) RN4 : 700 km ; accès au fleuve Congo.",
      "M_27":"Beni-Aéroport de Mavivi (Nord) RN4 : 13 km ; une piste de 2000 mètres de long et 20 mètres de large.",
      "M_28":"Beni-Kasindi (Est) RN4 : 80 km ; frontière Ouganda.",
      "M_29":"Beni-Butembo (Sud) RN2 : 57,5 km ; comme Beni, la ville de Butembo figure parmi les neuf villes socioéconomique de la RDC.",
      "M_30":"Beni-Goma (Sud) RN2 : 357,7 km ; Chef-lieu de la Province.Autres détails importants qui nécessitent des enquêtes plus appro-fondies afin de ressortir des chiffres exacts dans le but de trouver des solutions idoines à ces différents problèmes :",
      "M_31":"- Le taux d’alphabétisation de ces trois entités.",
      "M_32":"- Il s’observe une certaine dynamique économique chez certains commerçants de la place. Reste à mesurer l’impact réel qu’a cette activité sur le développement de cette région.",
      "M_h1":"Beni, Butembo et Territoire de Beni",
      
      // Val
      "Val_1":"Sentiment de bienveillance et de bonté à l’égard d’autrui.",
      "Val_11":"Nous croyons que l’éducation peut contribuer à la sauve-garde des vies et à l’amélioration des conditions de vie. Pour nous, chaque être humain a une valeur ; raison pour laquelle dé-fendons la Déclaration universelle des droits de l'homme adop-tée par l'Organisation des Nations Unies en date du 10 décembre 1948.",
      "Val_2":"Être conforme à ce que commandent le devoir et la conscience morale. Proverbes (livre de la Bible) 14 : 34 ‘’ La justice élève une nation…’’",
      "Val_21":"En parlant d’intégrité, nous pouvons aussi tenir compte de la moralité, de l’honnêteté, de la courtoisie, du respect. En inté-grant tous ces principes, nous pouvons commencer à construire une société juste, conforme aux principes de la justice et animée par les principes de droit et d’équité.",
      "Val_3":"Formation - Instruction",
      "Val_31":"Nous prônons une éducation de base de qualité pour tous les enfants, jeunes et adultes. Nous pensons que c’est l’un des meilleurs moyens pour élever un homme dans sa société et pour lui donner de la dignité.",
      "Val_4":"Qui dure longtemps et reste stable. Genèse (livre de la Bible) 2: 15 ' L’Eternel Dieu prit l’homme et le plaça dans le jardin d’Éden pour le cultiver et pour le garder.'",
      "Val_41":"Répondre aux besoins du présent sans compromettre la ca-pacité des générations futures à répondre à leurs propres besoins ; et prendre des mesures pour limiter ou supprimer l'impact néga-tif des activités de l'homme sur son environnement.",
      "Val_5":"Promotion de la femme",
      "Val_51":"Réduire la vulnérabilité des femmes en leur donnant des moyens de participer pleinement à la vie économique dans tous les secteurs de la vie.",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;